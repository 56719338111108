import React, {useState} from 'react';
import RichTextEditor from 'src/DesignSystem/RichTextEditor/RichTextEditor';
import {BackendQuery} from 'src/types/query.types';
import MessageView from 'src/common/CpaChat/MessageView';
import {makeStyles} from '@mui/styles';
import {
  Button,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from '@mui/material';
import {getEnvBasedConfig} from 'src/common/utils';
import {ContentPaste} from '@mui/icons-material';
import {v4 as uuidv4} from 'uuid';
import {appendSpreadsheet} from 'src/common/postGoogleSheets';
import {themmeColor} from 'src/constants/constants';
import QueryChatHeader from 'src/common/CpaChat/QueryChatHeader';
import {Transaction} from 'src/store/transactions/transactions.reducer';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import {getQueryResolutionModel} from 'src/store/queries/queries.utils';
import {QueryResolutionModel} from 'src/store/queries/queries.reducer';

export type SendMessageHandler = (
  message: string,
  extra: {
    isDraft: boolean;
    asDraft: boolean;
    draftId?: number;
    isTerminal?: boolean;
  },
) => Promise<void>;

type Props = {
  queryData: BackendQuery;
  flyUserId: number;
  cpaId: number;
  onSendMessage: SendMessageHandler;
  txnData: Record<number, Transaction>;
  onDoneEditDraft?: (draftId: number, message: string) => void;
  onDeleteDraft?: (draftId: number) => void;
};

const useStyles = makeStyles({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  messageContainer: {
    paddingTop: 12,
    paddingRight: 12,
    paddingLeft: 12,
    flex: 1,
    overflowY: 'auto',
  },
  rephrasedContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    border: `1px solid #dadada`,
    backgroundColor: '#f9f9f9',
    marginTop: 8,
    borderRadius: 4,
    paddingLeft: 8,
    paddingTop: 4,
    paddingBottom: 4,
  },
});
const CpaChat = ({
  queryData,
  flyUserId,
  cpaId,
  onSendMessage,
  onDeleteDraft = () => {},
  onDoneEditDraft = () => {},
  txnData,
}: Props) => {
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [typedMessage, setTypedMessage] = useState('');
  const [rephrasedMessage, setRephrasedMessage] = useState('');
  const [editDraftId, setEditDraftId] = useState<number | null>(null);
  const [messageTypePopup, setMessageTypePopup] = useState({
    isVisible: false,
    data: {},
  });
  const logEventToSheets = async (
    params: {textEditorId?: string; [key: string]: any} = {},
  ) => {
    const newRow = {
      userId: flyUserId,
      cpaId,
      randomId: params?.textEditorId ?? '',
      originalMessage: typedMessage,
      rephrasedMessage: rephrasedMessage,
      ...params,
    };
    await appendSpreadsheet(newRow);
  };

  const clearInputBox = () => setTypedMessage('');

  const handleSend = async (extraData: {
    isDraft?: boolean;
    asDraft?: boolean;
    draftId?: number | null;
    isTerminal?: boolean;
  }) => {
    console.log({extraData});
    try {
      setLoading(true);
      const extra = {
        asDraft: false,
        isDraft: false,
        isTerminal: false,
        ...extraData,
      };
      await onSendMessage(typedMessage, extra);
      setMessageTypePopup({isVisible: false, data: {}});
      clearInputBox();
    } finally {
      setLoading(false);
    }
  };

  const handleSendAndDraftEdit = async (
    extraData: {
      isDraft?: boolean;
      asDraft?: boolean;
      draftId?: number | null;
    } = {},
  ) => {
    if (editDraftId !== null) {
      setLoading(true);
      await onDoneEditDraft(editDraftId, typedMessage);
      setEditDraftId(null);
      clearInputBox();
      setLoading(false);
    } else if (
      getQueryResolutionModel(
        queryData.type,
        queryData.conversation?.[0].author_type,
      ) !== QueryResolutionModel.TAX_FILING &&
      !extraData.asDraft
    ) {
      const extra = {
        asDraft: false,
        isDraft: false,
        isTerminal: false,
        ...extraData,
      };
      setMessageTypePopup({isVisible: true, data: extra});
    } else {
      const extra = {
        asDraft: false,
        isDraft: false,
        isTerminal: false,
        ...extraData,
      };
      handleSend(extra);
    }
  };

  const handleRephraseText = async () => {
    setLoading(true);
    const env = getEnvBasedConfig();
    const GPT_URL = `${env.REACT_APP_DATA_PROVIDER_URL}/website/tax-gpt/`;
    fetch(GPT_URL, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        is_self_employed: false,
        is_working_from_home: false,
        is_user_query_tax_related: false,
        og_prompt: `We are tax CPA. Please don't mention tax cpa or repharsing in the response. Please help me repharse - ${typedMessage}`,
        should_contain_base_prompt: false,
        conversation_thread: [],
        use_get_prompt_func: false,
        temperature: 0.5,
        max_tokens: false,
      }),
    })
      .then(async (stream) => {
        const data = stream.body;
        if (!data) {
          return;
        }

        const reader = data.getReader();
        const decoder = new TextDecoder();
        let done = false;

        let lastMessage = '';

        while (!done) {
          const {value, done: doneReading} = await reader.read();
          done = doneReading;
          const chunkValue = decoder.decode(value);
          lastMessage += chunkValue;
        }

        const randomId = uuidv4();
        // setTextEditorId(randomId);

        setRephrasedMessage(lastMessage);
        await logEventToSheets({
          textEditorId: randomId,
          eventName: 'rephrase_tax_query',
        });
      })
      .finally(() => setLoading(false));
  };

  const handlePasteRephrasedText = () => {
    setTypedMessage(rephrasedMessage);
    logEventToSheets({
      eventName: 'paste_rephrased_to_editor',
      rephrasedMessage: '',
      originalMessage: rephrasedMessage,
    });
    setRephrasedMessage('');
  };

  const handleEditDraft = (draftId: number) => {
    const draft = queryData.conversation.find(
      (message) => message.id === draftId,
    );
    if (!draft) return;
    setTypedMessage(draft.message);
    setEditDraftId(draftId);
  };

  return (
    <>
      {loading && <LinearProgress />}
      <div className={styles.container}>
        {/* message list view*/}
        <QueryChatHeader queryData={queryData} txnData={txnData} />
        <Divider color={themmeColor.silver} />
        <div className={styles.messageContainer}>
          {queryData.conversation.map((message) => {
            const isEditing = message.is_draft && message.id === editDraftId;
            return (
              <div style={{marginBottom: 40}}>
                <MessageView
                  editing={isEditing}
                  message={message}
                  onEdit={handleEditDraft}
                  onDelete={onDeleteDraft}
                  onSendDraft={(draftId) =>
                    handleSendAndDraftEdit({isDraft: true, draftId})
                  }
                />
              </div>
            );
          })}
        </div>
        <div>
          <RichTextEditor
            height={225}
            message={typedMessage}
            setMessage={setTypedMessage}
          />
          {rephrasedMessage.length > 0 && (
            <div className={styles.rephrasedContainer}>
              <div>
                <Typography style={{fontWeight: 'bold'}}>
                  Chat GPT rephrased response:
                </Typography>
                <Typography style={{fontWeight: 'normal'}}>
                  {rephrasedMessage}
                </Typography>
              </div>
              <IconButton
                onClick={handlePasteRephrasedText}
                sx={{alignSelf: 'center'}}
                color={'primary'}
                edge={'start'}>
                <ContentPaste />
              </IconButton>
            </div>
          )}
        </div>
        <Grid
          container
          gridRow={1}
          direction={'row'}
          columnGap={2}
          justifyContent={'space-between'}
          padding={1.2}>
          <Grid item columnSpacing={1} direction={'row'}>
            {/*  add buttons to show on the left bottom side of the input box*/}
          </Grid>
          <Grid item xs={'auto'} direction={'row'}>
            <Button
              onClick={handleRephraseText}
              variant="outlined"
              disabled={loading || typedMessage.length === 0}
              style={{marginRight: 12}}>
              Rephrase
            </Button>
            <Button
              onClick={() => handleSendAndDraftEdit({asDraft: true})}
              disabled={
                loading || typedMessage.length === 0 || editDraftId !== null
              }
              style={{marginRight: 12}}
              variant="outlined">
              Save as draft
            </Button>
            <Button
              onClick={() => handleSendAndDraftEdit()}
              disabled={loading || typedMessage.length === 0}
              variant="contained">
              {editDraftId !== null ? 'Done editing' : 'Send'}
            </Button>
          </Grid>
        </Grid>
      </div>
      <PopUp
        isOpen={messageTypePopup.isVisible}
        onClose={() => setMessageTypePopup({isVisible: false, data: {}})}
        primaryButtonTitle="Reply"
        primaryButtonOnClick={() =>
          handleSend({...messageTypePopup.data, isTerminal: false})
        }
        primaryButtonDisabled={loading}
        secondaryButtonTitle="Terminal message"
        secondaryButtonOnClick={() =>
          handleSend({...messageTypePopup.data, isTerminal: true})
        }
        secondaryButtonDisabled={loading}>
        <Typography style={{fontWeight: 600, fontSize: 20}}>
          Is this a terminal message?
        </Typography>
        <Typography style={{color: themmeColor.grey}}>
          If this is the final message that you believe effectively conveys the
          concept and
          <br />
          resolves the user's query, please click on "Terminal Message."
        </Typography>
      </PopUp>
    </>
  );
};

export default CpaChat;
