import {Typography} from '@mui/material';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {postDocumentStatus} from 'src/appApi';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {VALID_DOCUMENT_STATUS} from 'src/constants/constants';
import MessageTextBox from 'src/CpaCenterV2/common/MessageTextBox/MessageTextBox';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import useQueryAction from 'src/CpaCenterV2/hooks/useQueryAction';
import Drawer from 'src/DesignSystem/Drawer/Drawer';
import CrossIcon from 'src/icons/CrossIcon';
import {setReuploadDocumentQueryDrawer} from 'src/store/app/app.actions';
import {selectReuploadDocumentQueryDrawer} from 'src/store/app/app.selector';
import {fetchDocumentsForGivenYear} from 'src/store/documents/documents.actions';
import {fetchQueries} from 'src/store/queries/queries.actions';

const ReuploadDocumentQueryDrawer = () => {
  const {isVisible, querySubType, docTypeId, docId} = useSelector(
    selectReuploadDocumentQueryDrawer,
  );
  const {activeYear} = useActiveYear();
  const {userId} = useCurrentUserId();
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');

  const {createReuploadDocumentQuery} = useQueryAction();

  const [loading, setLoading] = useState(false);

  const onCloseDrawer = () => {
    dispatch(
      setReuploadDocumentQueryDrawer({
        isVisible: false,
        querySubType: undefined,
        docTypeId: -1,
        docId: -1,
      }),
    );
    setMessage('');
  };

  const onSubmitQuery = async (isDraft = false) => {
    try {
      setLoading(true);
      await postDocumentStatus({
        tfd_info_id: docId,
        status: VALID_DOCUMENT_STATUS.ACTION_REQUIRED,
        reason: querySubType,
      });
      await createReuploadDocumentQuery({
        message,
        doc_type_id: docTypeId,
        doc_id: docId,
        isDraft,
        sub_type: querySubType,
      });
      dispatch(fetchDocumentsForGivenYear(userId, activeYear));
      dispatch(fetchQueries({userId}));
      onCloseDrawer();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer
      backgroundColor={'white'}
      width={400}
      height={'90vh'}
      isOpen={isVisible}
      direction="left">
      <div style={{padding: 12}}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <Typography style={{fontSize: 20, fontWeight: 600, marginBlock: 16}}>
            Specify why this is '{querySubType}'
          </Typography>
          <div onClick={onCloseDrawer}>
            <CrossIcon />
          </div>
        </div>
        <MessageTextBox
          inputMode={MessageTextBox.MessageTextBoxInputMode.SEND_MESSAGE}
          onSend={() => onSubmitQuery(false)}
          onDraft={() => onSubmitQuery(true)}
          isLoading={loading}
          placeholder="Specify reason"
          height={200}
          message={message}
          setMessage={setMessage}
        />
      </div>
    </Drawer>
  );
};

export default ReuploadDocumentQueryDrawer;
