import React, {useEffect, useMemo, useState} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import ReactHtmlParser from 'react-html-parser';

import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {themmeColor} from 'src/constants/constants';

import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';

import {useDispatch, useSelector} from 'react-redux';
import DSButton from 'src/DesignSystem/Button/Button';

import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {
  selectLastMessage,
  selectQueriesReducer,
} from 'src/store/queries/queries.selector';
import {getFilteredQueries} from 'src/CpaCenterV2/UserQueries/UserQueries.utils';
import {
  DerivedQueryType,
  MessageAuthorType,
  ReduxQueryType,
} from 'src/store/queries/queries.reducer';
import {selectTaxReturnsReducer} from 'src/store/taxReturns/taxReturns.selector';
import Dropdown from 'src/DesignSystem/Dropdown/Dropdown';
import {ExpandMoreOutlined} from '@mui/icons-material';
import {
  getReturnName,
  getTaxProfileSectionFromSubsection,
} from 'src/CpaCenterV2/common/CpaCenterV2.utils';
import Box from 'src/DesignSystem/Box/Box';
import Tag from 'src/DesignSystem/Tag/Tag';
import Message from 'src/CpaCenterV2/UserQueries/components/Messages/Message/Message';
import {selectBusinessDetails} from 'src/store/businessDetails/businessDetails.selector';
import {TaxReturnType} from 'src/store/taxReturns/taxReturns.reducer';
import {bulkUpdateQueries} from 'src/appApi';
import {fetchQueries} from 'src/store/queries/queries.actions';

const INDIVIDUAL_RETURN_VALUE = 'INDIVIDUAL_RETURN_VALUE';

const QueryUI = ({
  query,
  assignReturnOptions,
  assignReturnToQuery,
}: {
  query: ReduxQueryType;
  assignReturnOptions: {
    returnId: string | number;
    returnName: string;
  }[];
  assignReturnToQuery: (
    queryId: number,
    returnId: number | null,
    returnType: TaxReturnType,
  ) => void;
}) => {
  const [expandAccordion, setExpandAccordion] = useState<boolean>(false);

  const toggleAcordion = () => {
    setExpandAccordion((prev) => !prev);
  };

  const getQueryTagName = () => {
    if (query.derivedQueryType === DerivedQueryType.TAX_PROFILE_CPA) {
      return query.taxProfileSubsection;
    }
    if (
      [
        DerivedQueryType.REUPLOAD_DOCUMENT,
        DerivedQueryType.UPLOAD_NEW_DOCUMENT,
      ].includes(query.derivedQueryType)
    ) {
      return query.docData?.docType;
    }
    if (query.derivedQueryType === DerivedQueryType.HIGH_VALUE_DEDUCTION) {
      return 'Review Deductions';
    }
    if (query.derivedQueryType === DerivedQueryType.TAX_PROFILE) {
      return 'User Tax Profile';
    }
    if (query.derivedQueryType === DerivedQueryType.GENERAL) {
      return 'General';
    }
    if (query.derivedQueryType === DerivedQueryType.REVIEW_ESIGN) {
      return 'Review Esign';
    }
    return '<not implemented>';
  };

  const latestMessage = selectLastMessage(query);

  const authorName =
    latestMessage?.authorType === MessageAuthorType.END_USER
      ? latestMessage?.authorName
      : 'You';

  const {messages = []} = query ?? {};

  return (
    <div
      style={{
        marginTop: 10,
        display: 'flex',
        justifyContent: 'space-between',
      }}>
      <Accordion
        expanded={expandAccordion}
        style={{background: '#F3F3F3', flex: 0.7}}>
        <AccordionSummary
          onClick={(e) => toggleAcordion()}
          expandIcon={<ExpandMoreOutlined />}
          aria-controls="panel1a-content">
          <Box
            col
            backgroundColor={'#F3F3F3'}
            hoverEffect={false}
            onClick={() => toggleAcordion()}
            noBR={true}>
            <div
              style={{
                display: 'flex',

                alignItems: 'center',
              }}>
              <Tag
                text={getQueryTagName()}
                backgroundColor={themmeColor.white}
                borderColor={themmeColor.offWhite}
              />
            </div>
            {!expandAccordion && (
              <Typography
                style={{
                  fontSize: 16,
                  fontWeight: 400,
                  marginTop: 10,
                  display: 'flex',
                }}>
                <Typography style={{fontWeight: 600, fontSize: 16}}>
                  {`${authorName}: `}
                </Typography>
                <div style={{height: 48, overflow: 'hidden', marginLeft: 4}}>
                  {ReactHtmlParser(latestMessage.message)}
                </div>
              </Typography>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            col
            hoverEffect={false}
            onClick={() => toggleAcordion()}
            noBR={true}
            style={{padding: 12, borderRadius: 12}}>
            {query?.messages?.map((message, index) => (
              <div
                key={message.messageId}
                style={{
                  marginBottom: index !== messages.length - 1 ? 20 : 0,
                }}>
                <Message message={message} />
              </div>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
      <div style={{flex: 0.28}}>
        <Dropdown
          options={assignReturnOptions.map((option) => option.returnId)}
          value={
            query.assigned_return_type === TaxReturnType.INDIVIDUAL
              ? INDIVIDUAL_RETURN_VALUE
              : query.biz_return_id
          }
          onChange={(value: number | string) =>
            assignReturnToQuery(
              query.queryId,
              typeof value === 'string' ? null : value,
              value === INDIVIDUAL_RETURN_VALUE
                ? TaxReturnType.INDIVIDUAL
                : TaxReturnType.BUSINESS,
            )
          }
          placeholder={'Select tax return'}
          getOptionLabel={(value) =>
            assignReturnOptions.find((option) => option.returnId === value)
              ?.returnName
          }
        />
      </div>
    </div>
  );
};

const QuerySection = ({
  section,
  queries,
  assignReturnOptions,
  assignReturnToQuery,
}: {
  section: string;
  queries: ReduxQueryType[];
  assignReturnOptions: {
    returnId: string | number;
    returnName: string;
  }[];
  assignReturnToQuery: (
    queryId: number,
    returnId: number | null,
    returnType: TaxReturnType,
  ) => void;
}) => {
  return (
    <div
      style={{
        borderColor: '#F0F0F0',
        borderWidth: 2,
        borderRadius: 12,
        padding: 20,
        marginTop: 20,
      }}>
      <Typography
        style={{fontWeight: 600, fontSize: 16, color: themmeColor.black60}}>
        {section}
      </Typography>
      <div>
        {queries.map((query) => {
          return (
            <QueryUI
              query={query}
              key={query.queryId}
              assignReturnOptions={assignReturnOptions}
              assignReturnToQuery={assignReturnToQuery}
            />
          );
        })}
      </div>
    </div>
  );
};

interface AssignUserQueriesPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const AssignUserQueriesPopup = ({
  isOpen,
  onClose,
  onConfirm,
}: AssignUserQueriesPopupProps) => {
  const {activeYear} = useActiveYear();
  const {userId} = useCurrentUserId();
  const dispatch = useDispatch();

  const {queries, loaded: queriesLoaded} = useSelector(selectQueriesReducer);
  const {taxReturns, loaded: taxReturnsLoaded} = useSelector(
    selectTaxReturnsReducer,
  );
  const {businesses, loaded: businessLoaded} = useSelector(
    selectBusinessDetails,
  );

  const isLoaded = taxReturnsLoaded && businessLoaded && queriesLoaded;

  const [queriesObj, setQueriesObj] = useState<{
    [key: number]: ReduxQueryType;
  }>({});
  const [isLoading, setIsLoading] = useState(false);

  const {notify} = useNotify();

  const relevantQueries = useMemo(
    () => getFilteredQueries(queries),
    [queries],
  );

  useEffect(() => {
    setQueriesObj(() => {
      return relevantQueries.reduce((obj, query) => {
        obj[query.queryId] = query;
        return obj;
      }, {} as {[key: number]: ReduxQueryType});
    });
  }, [relevantQueries]);

  const groupedQueries = useMemo(() => {
    return Object.values(queriesObj).reduce((obj, query) => {
      let section: string = DerivedQueryType.UNKNOWN;
      if (query.taxProfileSubsection != null) {
        section = getTaxProfileSectionFromSubsection(
          query.taxProfileSubsection,
        );
      } else {
        section = query.derivedQueryType;
      }

      obj[section] = [...(obj[section] ?? []), query];
      return obj;
    }, {} as {[key: string]: ReduxQueryType[]});
  }, [queriesObj]);

  const assignReturnOptions = useMemo(() => {
    return taxReturns.map((taxReturn) => {
      return {
        returnId:
          taxReturn.return_type === TaxReturnType.INDIVIDUAL
            ? INDIVIDUAL_RETURN_VALUE
            : taxReturn.return_id,
        returnName: getReturnName(taxReturn, businesses),
      };
    });
  }, [taxReturns, businesses]);

  const primaryButtonOnClick = async () => {
    try {
      setIsLoading(true);
      await bulkUpdateQueries({
        questions: Object.values(queriesObj).map((query) => ({
          id: query.queryId,
          biz_return_id: query.biz_return_id,
          assigned_return_type: query.assigned_return_type,
        })),
      });
      dispatch(fetchQueries({userId}));
      onClose();
      onConfirm();
      notify('Successfully assigned queries', NotificationType.success);
    } catch (e) {
      notify('Failed to assign queries', NotificationType.error);
    } finally {
      setIsLoading(false);
    }
  };

  const assignReturnToQuery = (
    queryId: number,
    returnId: number | null,
    returnType: TaxReturnType,
  ) => {
    if (returnType === TaxReturnType.BUSINESS) {
      setQueriesObj((prev: {[key: number]: ReduxQueryType}) => {
        return {
          ...prev,
          [queryId]: {
            ...queriesObj[queryId],
            biz_return_id: returnId,
            assigned_return_type: returnType,
          },
        };
      });
    } else {
      setQueriesObj((prev: {[key: number]: ReduxQueryType}) => {
        return {
          ...prev,
          [queryId]: {
            ...queriesObj[queryId],
            assigned_return_type: returnType,
          },
        };
      });
    }
  };

  const isPrimaryButtonDisabled = useMemo(() => {
    return Object.values(queriesObj).some(
      (query) => query.assigned_return_type == null,
    )
      ? true
      : false;
  }, [queriesObj]);

  return (
    <PopUp isOpen={isOpen} onClose={onClose}>
      <Typography style={{fontWeight: 800, fontSize: 20}}>
        Assign entity name to all queries
      </Typography>
      <Typography style={{fontSize: 13, marginTop: 4, marginBottom: 2}}>
        Assign entities to all queries to so that users can focus on one return
        at a time.
      </Typography>
      <div style={{width: '80vw', maxHeight: '70vh', overflowY: 'auto'}}>
        {Object.entries(groupedQueries).map(([section, sectionQueries]) => {
          return (
            <QuerySection
              section={section}
              queries={sectionQueries}
              key={section}
              assignReturnOptions={assignReturnOptions}
              assignReturnToQuery={assignReturnToQuery}
            />
          );
        })}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
          marginTop: 12,
        }}>
        <DSButton
          type="primary"
          text={'Confirm'}
          onClick={primaryButtonOnClick}
          disabled={isLoading || isPrimaryButtonDisabled}
          style={{flex: 0.3}}
        />
        <DSButton
          type="secondary"
          text={'Cancel'}
          onClick={onClose}
          style={{flex: 0.3}}
          disabled={isLoading}
        />
      </div>
    </PopUp>
  );
};

export default AssignUserQueriesPopup;
