import {getUserInfo} from 'src/appApi';
import {TaxFilingStatus} from 'src/appApi.types';
import {SET_USER_INFO} from '../actionNames';
import {setAppState} from '../app/app.actions';
import {AppReducerStates, NotificationType} from '../app/app.reducer';
import {UserStateType} from './user.reducer';
import {selectActiveYear} from '../globals/globals.selector';
import {getStateType} from '../store';

const setUserInfo = (data: TaxFilingStatus, activeYear: number) => {
  const {results} = data;
  const {
    fly_user_id,
    year,
    fly_user_name,
    email,
    cch_client_id,
    cpa_facing_ret_status,
    id,
    last_status_change_date,
    notes,
    next_action_date,
    user_filing_status,
    comms_mode,
    tax_extension_status,
    esign_mode,
    date_of_debit,
    tax_filing_plan_name,
    tf_segment,
  } = results.find((item) => item.year === activeYear) as (typeof results)[0];
  let cchClientId = 0;
  if (cch_client_id) {
    cchClientId = parseInt(cch_client_id, 10);
  }
  //   @ts-ignore
  const payload: UserStateType = {
    userId: fly_user_id,
    userName: fly_user_name,
    email,
    cchClientId,
    cpaFacingRetStatus: cpa_facing_ret_status,
    taxFilingId: id,
    lastStatusChange: last_status_change_date,
    notes,
    nextActionDate: next_action_date,
    userFilingStatus: user_filing_status,
    year,
    comms_mode,
    tax_extension_status,
    esign_mode,
    dateOfDebit: date_of_debit,
    taxFilingPlanName: tax_filing_plan_name,
    tfSegment: tf_segment,
  };
  return {
    type: SET_USER_INFO,
    payload,
  };
};

export const fetchUserInfo = (userId: number) => {
  return async (dispatch: any, getState: getStateType) => {
    const activeYear = selectActiveYear(getState());
    try {
      const userInfo = await getUserInfo({
        fly_user_id: userId,
        year: activeYear,
      });
      dispatch(setUserInfo(userInfo.data, activeYear));
    } catch (e) {
      dispatch(
        setAppState(AppReducerStates.notifcationStatus, {
          isVisible: true,
          message: `Failed to fetch user info (${e})`,
          type: NotificationType.error,
        }),
      );
    }
  };
};
