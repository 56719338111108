import React from 'react';
import {
  DOCUMENT_TYPES,
  TaxProfileSubsection,
  themmeColor,
} from 'src/constants/constants';
import SubSectionBox from '../../common/SubSectionBox';
import {Typography} from '@mui/material';
import CommonDeductionTable from './CommonDeductionTable';
import {useSelector} from 'react-redux';
import {selectTaxProfileAnswer} from 'src/store/taxProfile/taxProfile.selector';
import {TAX_PROFILE_FIELDS} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import {format} from 'date-fns';
import SimpleKeyValueField from '../../common/SimpleKeyValueField';
import {selectDocuments} from 'src/store/documents/documents.selector';
import DocumentPointer from 'src/CpaCenterV2/common/DocumentPointer/DocumentPointer';
import {
  TaxProfileSummaryVariant,
  useTaxProfileSummaryFilter,
} from '../../common/TaxProfileSummaryFilter/useTaxProfileSummaryFilter';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

const getMonthName = (month: number) => {
  const date = new Date();
  date.setMonth(month - 1);
  return format(new Date(date), 'MMMM');
};

const HealthInsurancePremium = () => {
  const {activeYear} = useActiveYear();
  const {documents} = useSelector(selectDocuments);

  const paidHealthInsurancePremium = useSelector((state) =>
    selectTaxProfileAnswer(state, TAX_PROFILE_FIELDS.PAID_HEALTH_INS_PREMIUM),
  );
  const healthInsuranceCombinedMonths: Array<number> =
    useSelector((state) =>
      selectTaxProfileAnswer(
        state,
        TAX_PROFILE_FIELDS.HEALTH_INS_COMBINED_MONTHS,
      ),
    ) ?? [];
  const purchasedFromMarket = useSelector((state) =>
    selectTaxProfileAnswer(
      state,
      TAX_PROFILE_FIELDS.IS_HEALTH_INS_MARKET_PURCHASED,
    ),
  );
  const {summaryVariant} = useTaxProfileSummaryFilter();

  const fields = [
    {
      name: `Did you pay for health insurance premiums in ${activeYear}?`,
      value: paidHealthInsurancePremium,
    },
    {
      name: `Select the months for which you or your spouse paid for insurance out of pocket?`,
      value: healthInsuranceCombinedMonths.map(getMonthName).join(', '),
    },
    {
      name: `Did you purchase this through health insurance marketplace?`,
      value: purchasedFromMarket,
    },
  ];

  return (
    <SubSectionBox
      name="Health Insurance premium"
      taxprofileSubsection={TaxProfileSubsection.HealthInsurancePremium}>
      <div
        style={
          summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
            ? {}
            : {display: 'flex', flex: 1, justifyContent: 'space-between'}
        }>
        <div
          style={
            summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
              ? {}
              : {flex: 0.48}
          }>
          {fields.map((field) => (
            <SimpleKeyValueField {...field} />
          ))}
          <Typography
            style={{
              fontSize: 11,
              color: themmeColor.black60,
              marginBottom: 4,
            }}>
            Related document
          </Typography>
          <DocumentPointer
            doc={documents.find(
              (doc) => doc.docType === DOCUMENT_TYPES.MEDICAL_EXPENSE_PROOF,
            )}
            dontShowStatus
            border
          />
        </div>
        <div
          style={
            summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
              ? {marginTop: 20}
              : {flex: 0.48}
          }>
          <CommonDeductionTable
            taxProfileSubsection={TaxProfileSubsection.HealthInsurancePremium}
            // taxforms={healthInsuranceTFs}
          />
        </div>
      </div>
    </SubSectionBox>
  );
};

export default HealthInsurancePremium;
