import React from 'react';
import {useSelector} from 'react-redux';
import {TaxProfileSubsection} from 'src/constants/constants';
import {
  FIELD_ID,
  TAX_PROFILE_FIELDS,
} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import SimpleKeyValueField from 'src/CpaCenterV2/TaxProfileSections/common/SimpleKeyValueField';
import SubSectionBox from 'src/CpaCenterV2/TaxProfileSections/common/SubSectionBox';
import {FILING_STATUS_ANSWER} from 'src/CpaCenterV2/TaxProfileSections/TaxProfileSections.constants';
import {ReduxStateType} from 'src/store/store';
import {
  selectTaxProfile,
  selectTaxProfileAnswer,
} from 'src/store/taxProfile/taxProfile.selector';

interface SpouseDetailsProps {
  style?: React.CSSProperties;
}

const SpouseDetails = ({style}: SpouseDetailsProps) => {
  const {loaded} = useSelector(selectTaxProfile);

  const filingStatus = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TAX_PROFILE_FIELDS.FILING_STATUS),
  );
  const spouseLegalName = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TAX_PROFILE_FIELDS.SPOUSE_LEGAL_NAME),
  );
  const spouseSSN = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TAX_PROFILE_FIELDS.SPOUSE_SSN),
  );
  const spouseDOB = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TAX_PROFILE_FIELDS.SPOUSE_DOB),
  );
  const spouseEmail = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TAX_PROFILE_FIELDS.SPOUSE_EMAIL),
  );

  const canSomeoneElseClaimSpouseAsDependent = useSelector(
    (state: ReduxStateType) =>
      selectTaxProfileAnswer(
        state,
        TAX_PROFILE_FIELDS.SPOUSE_CLAIMED_DEPENDENT,
      ),
  );

  return (
    <SubSectionBox
      name="Spouse details"
      style={style}
      taxprofileSubsection={TaxProfileSubsection.SpouseDetails}>
      {[
        FILING_STATUS_ANSWER.MARRIED_FILING_JOINTLY,
        FILING_STATUS_ANSWER.MARRIED_FILING_SEPARAELY,
      ].includes(filingStatus) && (
        <>
          <SimpleKeyValueField
            name="Spouse first name & middle initial"
            style={{marginBottom: 12}}
            value={spouseLegalName?.[FIELD_ID.FIRST_NAME]}
            loading={!loaded}
          />
          <SimpleKeyValueField
            name="Spouse last name"
            style={{marginBottom: 12}}
            value={spouseLegalName?.[FIELD_ID.LAST_NAME]}
            loading={!loaded}
          />
          <SimpleKeyValueField
            name="Social Security Number"
            style={{marginBottom: 12}}
            value={spouseSSN}
            loading={!loaded}
          />
          {filingStatus === FILING_STATUS_ANSWER.MARRIED_FILING_JOINTLY && (
            <>
              <SimpleKeyValueField
                name="Date of birth"
                style={{marginBottom: 12}}
                value={spouseDOB}
                loading={!loaded}
              />
              <SimpleKeyValueField
                name="Spouse email"
                style={{marginBottom: 12}}
                value={spouseEmail}
                loading={!loaded}
              />
              <SimpleKeyValueField
                name="Can your spouse be claimed as someone’s dependent?"
                value={canSomeoneElseClaimSpouseAsDependent}
                style={{marginBottom: 12}}
                loading={!loaded}
              />
            </>
          )}
        </>
      )}
    </SubSectionBox>
  );
};

export default SpouseDetails;
