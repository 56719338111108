import {SET_APP_STATE} from '../actionNames';
import {QuerySubType} from '../queries/queries.reducer';
import {TaxReturnType} from '../taxReturns/taxReturns.reducer';
import {ReduxActionType} from '../types';

export enum NotificationType {
  error = 'error',
  warning = 'warning',
  info = 'info',
  success = 'success',
}

export enum CreateQueryDrawerTab {
  TAX_PROFILE = 'Tax Profile',
  DOCUMENT = 'Document',
  HIGH_VALUE_DEDUCTIONS = 'High Value Deductions',
  GENERAL = 'General',
  DISTRIBUTE_DEDUCTIONS = 'Distribute deductions',
}

export enum AppReducerStates {
  isTaxProfileSummaryDrawer = 'isTaxProfileSummaryDrawer',
  isCreateQueryDrawer = 'isCreateQueryDrawer',
  createQueryDrawerStatus = 'createQueryDrawerStatus',
  notifcationStatus = 'notifcationStatus',
  taxformDrawer = 'taxformDrawer',
  paymentDetailsDrawer = 'paymentDetailsDrawer',
  highValueDeductionsDrawer = 'highValueDeductionsDrawer',
  statesInfoDrawer = 'statesInfoDrawer',
  highValueDeductionsStatusDrawer = 'highValueDeductionsStatusDrawer',
  reuploadDocumentQueryDrawer = 'reuploadDocumentQueryDrawer',
  distributeDeductionsQueryDrawer = 'distributeDeductionsQueryDrawer',
  fileExtensionDetailsDrawer = 'fileExtensionDetailsDrawer',
  childCareExpenseDrawer = 'childCareExpenseDrawer',
  ipPinDetailsDrawer = 'ipPinDetailsDrawer',
}

const initialState = {
  [AppReducerStates.isTaxProfileSummaryDrawer]: false,
  [AppReducerStates.createQueryDrawerStatus]: {
    isVisible: false,
    selectedTab: CreateQueryDrawerTab.TAX_PROFILE,
    selectedDropdown: null,
    currentReturnId: null,
    currentReturnType: null,
  },
  [AppReducerStates.notifcationStatus]: {
    isVisible: false,
    type: NotificationType.info,
    message: '',
  },
  [AppReducerStates.taxformDrawer]: {
    isVisible: false,
    taxformId: -1,
  },
  [AppReducerStates.paymentDetailsDrawer]: {
    isVisible: false,
    returnId: -1,
    returnType: null,
  },
  [AppReducerStates.highValueDeductionsDrawer]: false,
  [AppReducerStates.statesInfoDrawer]: false,
  [AppReducerStates.highValueDeductionsStatusDrawer]: {
    isVisible: false,
    queryId: -1,
  },
  [AppReducerStates.reuploadDocumentQueryDrawer]: {
    isVisible: false,
    querySubType: undefined as undefined | QuerySubType,
    docTypeId: -1,
    docId: -1,
  },
  [AppReducerStates.distributeDeductionsQueryDrawer]: false,
  [AppReducerStates.fileExtensionDetailsDrawer]: false,
  [AppReducerStates.childCareExpenseDrawer]: {
    formId: -1,
    isVisible: false,
    title: '',
  },
  [AppReducerStates.ipPinDetailsDrawer]: false,
};

export const app = (state = initialState, action: ReduxActionType) => {
  switch (action.type) {
    case SET_APP_STATE:
      return {
        ...state,
        [action.payload.path]: action.payload.value,
      };
  }
  return state;
};

export type AppReducerType = typeof initialState;
