import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {BusinessType} from 'src/store/businessDetails/businessDetails.reducer';
import {selectBusinessDetails} from 'src/store/businessDetails/businessDetails.selector';
import {
  TaxReturn,
  TaxReturnType,
} from 'src/store/taxReturns/taxReturns.reducer';
import {selectTaxReturnsReducer} from 'src/store/taxReturns/taxReturns.selector';

const useCurrentTaxReturnId = () => {
  const {returnId: rawReturnData} = useParams();

  let returnId: number = null;
  let returnType: TaxReturnType = null;
  if (rawReturnData) {
    const [rawReturnId, rawReturnType] = rawReturnData.split('-');
    returnId = parseInt(rawReturnId, 10);
    returnType = rawReturnType as TaxReturnType;
  }

  const {taxReturns, loaded: taxReturnsLoaded} = useSelector(
    selectTaxReturnsReducer,
  );
  const {businesses, loaded: businessLoaded} = useSelector(
    selectBusinessDetails,
  );

  const currentReturn = taxReturns.find(
    (taxReturn) =>
      taxReturn.return_id === returnId && taxReturn.return_type === returnType,
  ) as TaxReturn;

  const currentReturnBizEntityType = businesses.find(
    (business) => business.id === currentReturn?.business_id,
  )?.entityType as BusinessType;

  return {
    returnId,
    returnType,
    currentReturn,
    currentReturnBizEntityType,
    isLoaded: taxReturnsLoaded && businessLoaded,
  };
};

export default useCurrentTaxReturnId;
