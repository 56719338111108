import {getDistributeDeductions} from 'src/appApi';
import {SET_DISTRIBUTE_DEDUCTIONS} from '../actionNames';
import {DistributeDeductionsStateType} from './distributeDeductions.reducer';
import {selectActiveYear} from '../globals/globals.selector';
import {ReduxStateType} from '../store';

export const setDistributeDeductions = (
  payload: Omit<DistributeDeductionsStateType, 'status' | 'loaded'>,
) => {
  return {
    type: SET_DISTRIBUTE_DEDUCTIONS,
    payload,
  };
};

export const fetchDistributeDeductions = (userId: number) => {
  return async (dispatch: any, getState: () => ReduxStateType) => {
    try {
      const activeYear = selectActiveYear(getState());
      const {data}: any = await getDistributeDeductions({
        fly_user_id: userId,
        year: activeYear,
      });
      dispatch(setDistributeDeductions({distributedDeductions: data}));
    } catch (e) {}
  };
};
