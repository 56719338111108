import {Typography} from '@mui/material';
import React from 'react';
import {useSelector} from 'react-redux';
import {
  FIELD_ID,
  TAX_PROFILE_FIELDS,
} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import {getFieldValue} from 'src/CpaCenterV2/common/CpaCenterV2.utils';
import DocumentPointer from 'src/CpaCenterV2/common/DocumentPointer/DocumentPointer';
import {selectDocuments} from 'src/store/documents/documents.selector';
import {ReduxStateType} from 'src/store/store';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.constants';
import {TaxForm} from 'src/store/taxForms/taxForms.reducer';
import {selectTaxProfileAnswer} from 'src/store/taxProfile/taxProfile.selector';
import SimpleKeyValueField from '../../common/SimpleKeyValueField';
import {FILING_STATUS_ANSWER} from '../../TaxProfileSections.constants';

interface DrivingLicenseProps {
  taxForm: TaxForm;
  isSpouse: boolean;
}

const DrivingLicense = ({isSpouse, taxForm}: DrivingLicenseProps) => {
  const legalName = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      isSpouse
        ? TAX_PROFILE_FIELDS.SPOUSE_LEGAL_NAME
        : TAX_PROFILE_FIELDS.LEGAL_NAME,
    ),
  );
  const filingStatus = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TAX_PROFILE_FIELDS.FILING_STATUS),
  );

  const {documents} = useSelector(selectDocuments);

  const fName = legalName?.[FIELD_ID.FIRST_NAME];
  const lName = legalName?.[FIELD_ID.LAST_NAME];

  const fullName = `${getFieldValue(fName)} ${getFieldValue(lName)} (${
    isSpouse ? 'Spouse' : 'Self'
  })`;

  const linkedDoc = documents.find((doc) => doc.docId === taxForm?.docId);

  return (
    <div style={{flex: 0.48}}>
      <Typography style={{fontWeight: 600, marginBottom: 20}}>
        {fullName}
      </Typography>
      {(!isSpouse ||
        filingStatus === FILING_STATUS_ANSWER.MARRIED_FILING_JOINTLY) && (
        <>
          <SimpleKeyValueField
            name="Issuing state"
            value={taxForm?.formData?.[TaxFormFieldIds.STATE]}
          />
          <Typography>Related document</Typography>
          <DocumentPointer
            taxform={taxForm}
            doc={linkedDoc}
            dontShowStatus
            border
          />
        </>
      )}
    </div>
  );
};

export default DrivingLicense;
