import React, {ReactNode, useMemo} from 'react';
import './InputWrapper.css';
import Text from '../../Text/Text';
import {themmeColor} from 'src/constants/constants';
import {VALIDATOR, getValidatorFunctions} from '../../../Form.validators';

const containerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
};

const rightIconStyle: React.CSSProperties = {
  position: 'absolute',
  right: 12, // Position icon to the right
  pointerEvents: 'none', // Prevent icon from interfering with input interaction
};

const titleContainerStyle: React.CSSProperties = {
  marginBottom: 4,
  marginLeft: 2,
};

const inputContainerStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  flex: 1,
};

const inputStyles: React.CSSProperties = {
  padding: '13px 12px',
  borderRadius: 8,
  outline: 'none',
  boxSizing: 'border-box',
  flex: 1,
};

export type InputWrapperBaseType = {
  title?: string;
  rightIcon?: ReactNode;
  validators?: VALIDATOR[];
};

type InputWrapperProps = {
  actualValue: any;
} & InputWrapperBaseType &
  React.InputHTMLAttributes<HTMLInputElement>;

const InputWrapper = ({
  title,
  rightIcon,
  validators = [],
  actualValue,
  ...props
}: InputWrapperProps) => {
  const style = useMemo(() => {
    if (!props.style) {
      return inputStyles;
    }
    return {...inputStyles, ...props.style};
  }, [props?.style]);

  const validatorFunctions = getValidatorFunctions(validators);

  const validationOutput = validatorFunctions.map((validator) => {
    return validator(actualValue);
  });

  const failedCases = validationOutput.filter((output) => !output.isValid);

  const isInvalid = failedCases.length > 0;

  return (
    <div style={containerStyle}>
      {title && (
        <Text
          type={Text.TEXT_TYPES.S}
          color={isInvalid ? themmeColor.errorRed : themmeColor.black60}
          text={title}
          containerStyle={titleContainerStyle}
        />
      )}
      <div style={{marginBottom: 12}}>
        <div style={inputContainerStyle}>
          <input
            {...props}
            style={{
              ...style,
              borderColor: isInvalid ? themmeColor.errorRed : undefined,
            }}
            className="input-wrapper"
          />
          {rightIcon && <span style={rightIconStyle}>{rightIcon}</span>}
        </div>
        {isInvalid && (
          <>
            <div
              style={{
                fontSize: 13,
                color: themmeColor.errorRed,
                marginTop: 4,
              }}>
              Error value is {JSON.stringify(actualValue)}
            </div>
            <ul style={{marginBottom: 0, marginTop: 4}}>
              {failedCases.map((failedCase) => (
                <li style={{color: themmeColor.errorRed, fontSize: 11}}>
                  {failedCase.message}
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default InputWrapper;
