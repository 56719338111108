import React, {useEffect, useMemo, useState} from 'react';
import {Button, Table, TableContainer} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {useOutletContext, useSearchParams} from 'react-router-dom';

import {
  DEFAULT_PER_PAGE,
  VALID_CPA_EMAILS,
  themmeColor,
} from 'src/constants/constants';
import {exportBoiColumns, getBoiFilingUserList} from 'src/appApi';
import FilterBar, {SearchPanelData} from 'src/common/FilterBar/FilterBar';
import ListTableHeader from 'src/common/Table/ListTableHeader';
import ListTableBody from 'src/common/Table/ListTableBody';
import CPAAssignCell from 'src/CpaCenterList/components/CPAAssignCell/CPAAssignCell';
import NormalReadOnlyCell from 'src/CpaCenterList/components/NormalReadOnlyCell/NormalReadOnlyCell';
import Pagination from 'src/common/Pagination/Pagination';
import {parse} from 'querystring';
import {Mail, Smartphone} from '@mui/icons-material';
import {NotificationType} from 'src/store/app/app.reducer';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import useLayout from 'src/CpaCenterV2/hooks/useLayout';
import {
  BOI_FILING_COLUMNS,
  BOI_FILTER_PARAMS,
  BoiFilingData,
  SUBMIT_TYPE,
} from './types';
import {BOI_LIST_COLUMN_NAMES} from './constants';
import {format} from 'date-fns';
import BoiStatusCell from './components/BoiStatusCell';
import BoiListFilterModal from './components/BoiListFilterModal';
import {BoiFilingCenterRoutesParts} from 'src/constants/routeName';

const BoiFilingList: React.FC = () => {
  const {openDrawer} = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const defaultUserId = searchParams.get(BOI_FILTER_PARAMS.FLY_USER_ID);
  const defaultEmail = searchParams.get(BOI_FILTER_PARAMS.EMAIL);
  const defaultName = searchParams.get(BOI_FILTER_PARAMS.NAME);
  const defaultPageCount = searchParams.get('pageCount');
  const pageCount = defaultPageCount ? parseInt(defaultPageCount) : 1;
  const defaultSelectedPaymentStatus = searchParams.get(
    BOI_FILTER_PARAMS.PAYMENT_STATUS,
  );

  const defaultSelectedBoiStatus = searchParams.getAll(
    BOI_FILTER_PARAMS.STATUS,
  );
  const defaultSelectedReviewers = searchParams.getAll(
    BOI_FILTER_PARAMS.ASSIGNED_REVIEWER,
  );
  const defaultSelectedSubmitType = searchParams.getAll(
    BOI_FILTER_PARAMS.SUBMIT_TYPE,
  );

  const [isPrev, setIsPrev] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [recordsArray, setRecordsArray] = useState<Array<BoiFilingData>>([]);
  const [totalPages, setTotalPages] = useState(1);

  const {notify} = useNotify();
  const {windowSize} = useLayout();

  const NAV_BAR_HEIGHT = 150;
  const FOOTER_HEIGHT = 60;
  const MAIN_CONTENT_HEIGHT = windowSize.height - NAV_BAR_HEIGHT;
  const USER_LIST_HEIGHT = MAIN_CONTENT_HEIGHT - FOOTER_HEIGHT;

  const getIdEmailAndNameFilteringKeys = () => {
    let filters: {
      [BOI_FILTER_PARAMS.FLY_USER_ID]?: string;
      [BOI_FILTER_PARAMS.EMAIL]?: string;
      [BOI_FILTER_PARAMS.NAME]?: string;
    } = {};
    if (defaultUserId) filters[BOI_FILTER_PARAMS.FLY_USER_ID] = defaultUserId;
    if (defaultEmail) filters[BOI_FILTER_PARAMS.EMAIL] = defaultEmail;
    if (defaultName) filters[BOI_FILTER_PARAMS.NAME] = defaultName;
    return filters;
  };

  const getFilterParams = () => {
    return {
      per_page: DEFAULT_PER_PAGE,
      page: pageCount,
      ...getIdEmailAndNameFilteringKeys(),
      [BOI_FILTER_PARAMS.ASSIGNED_REVIEWER]: defaultSelectedReviewers,
      [BOI_FILTER_PARAMS.STATUS]: defaultSelectedBoiStatus,
      [BOI_FILTER_PARAMS.SUBMIT_TYPE]: defaultSelectedSubmitType,
      [BOI_FILTER_PARAMS.PAYMENT_STATUS]: defaultSelectedPaymentStatus
        ? defaultSelectedPaymentStatus
        : undefined,
    };
  };

  const onClearFilter = () => {
    setSearchParams({});
  };

  const fetchUserList = async () => {
    try {
      setIsLoading(true);
      const params = getFilterParams();
      const response = await getBoiFilingUserList(params);
      setRecordsArray(response.data.results);
      setIsPrev(!!response.data.previous);
      setIsNext(!!response.data.next);
      setTotalPages(Math.ceil(response.data.count / DEFAULT_PER_PAGE));
      setIsLoading(false);
    } catch (e) {
      notify(`Failed to fetch BOI users - ${e}`, NotificationType.error);
      onClearFilter();
    }
  };

  const onClickSearchHandler = (data: SearchPanelData) => {
    setSearchParams((prevSearchParams) => {
      if (data.userId != null)
        prevSearchParams.set(BOI_FILTER_PARAMS.FLY_USER_ID, data.userId);
      if (data.email != null)
        prevSearchParams.set(BOI_FILTER_PARAMS.EMAIL, data.email);
      if (data.name != null)
        prevSearchParams.set(BOI_FILTER_PARAMS.NAME, data.name);
      prevSearchParams.set('pageCount', '1');
      return prevSearchParams;
    });
  };

  const closeFilterModal = () => setIsFilterModalOpen(false);
  const openFilterModal = () => setIsFilterModalOpen(true);

  const getBoiListColumnComponentMapper = () => {
    const columns = [];

    const defaultColumns = [
      {
        label: BOI_LIST_COLUMN_NAMES[BOI_FILING_COLUMNS.FLY_USER_ID],
        render: (record: BoiFilingData) => (
          <NormalReadOnlyCell
            value={`${record[BOI_FILING_COLUMNS.FLY_USER_ID]}`}
          />
        ),
        backendResponseKey: BOI_FILING_COLUMNS.FLY_USER_ID,
      },
      {
        label: BOI_LIST_COLUMN_NAMES[BOI_FILING_COLUMNS.FLY_USER_NAME],
        render: (record: BoiFilingData) => (
          <NormalReadOnlyCell
            value={record[BOI_FILING_COLUMNS.FLY_USER_NAME]}
          />
        ),
        backendResponseKey: BOI_FILING_COLUMNS.FLY_USER_NAME,
      },
      {
        label: BOI_LIST_COLUMN_NAMES[BOI_FILING_COLUMNS.SUBMIT_TYPE],
        render: (record: BoiFilingData) =>
          record[BOI_FILING_COLUMNS.SUBMIT_TYPE] ? (
            record[BOI_FILING_COLUMNS.SUBMIT_TYPE] === SUBMIT_TYPE.BOI_FORM ? (
              <Smartphone />
            ) : (
              <Mail />
            )
          ) : null,
        backendResponseKey: BOI_FILING_COLUMNS.SUBMIT_TYPE,
      },
      {
        label: BOI_LIST_COLUMN_NAMES[BOI_FILING_COLUMNS.STATUS],
        render: (record: BoiFilingData) => (
          <BoiStatusCell
            onSuccess={fetchUserList}
            boiId={record.id}
            currentBoiStatus={record[BOI_FILING_COLUMNS.STATUS]}
          />
        ),
        backendResponseKey: BOI_FILING_COLUMNS.STATUS,
      },
      {
        label: BOI_LIST_COLUMN_NAMES[BOI_FILING_COLUMNS.ASSIGNED_REVIEWER],
        render: (record: BoiFilingData) => (
          <CPAAssignCell
            fieldId={BOI_FILING_COLUMNS.ASSIGNED_REVIEWER}
            value={record[BOI_FILING_COLUMNS.ASSIGNED_REVIEWER]}
            boiId={record.id}
            fetchList={fetchUserList}
            isAssigned={
              ![null, VALID_CPA_EMAILS.Unassigned].includes(
                record[BOI_FILING_COLUMNS.ASSIGNED_REVIEWER],
              )
            }
          />
        ),
        backendResponseKey: BOI_FILING_COLUMNS.ASSIGNED_REVIEWER,
      },
      {
        label: BOI_LIST_COLUMN_NAMES[BOI_FILING_COLUMNS.USER_SUBMITTED_AT],
        render: (record: BoiFilingData) => {
          return (
            <NormalReadOnlyCell
              value={
                record[BOI_FILING_COLUMNS.USER_SUBMITTED_AT]
                  ? format(
                      new Date(
                        record[BOI_FILING_COLUMNS.USER_SUBMITTED_AT] ?? '',
                      ),
                      'MMM d, yyyy',
                    )
                  : ''
              }
            />
          );
        },
        backendResponseKey: BOI_FILING_COLUMNS.USER_SUBMITTED_AT,
      },
      {
        label: BOI_LIST_COLUMN_NAMES[BOI_FILING_COLUMNS.PAYMENT_STATUS],
        render: (record: BoiFilingData) => (
          <NormalReadOnlyCell
            value={record[BOI_FILING_COLUMNS.PAYMENT_STATUS]}
          />
        ),
        backendResponseKey: BOI_FILING_COLUMNS.PAYMENT_STATUS,
      },
    ];

    columns.push(...defaultColumns);

    return columns;
  };

  const exportBoi = async () => {
    try {
      await exportBoiColumns();
      notify('Excel sheet sent to your email', NotificationType.success);
    } catch (e) {
      notify('Export failed', NotificationType.error);
    }
  };

  const boiListColumnComponentMapper = getBoiListColumnComponentMapper();

  const defaultSelectedBoiStatusString = useMemo(
    () => JSON.stringify(defaultSelectedBoiStatus),
    [defaultSelectedBoiStatus],
  );

  const defaultSelectedReviewersString = useMemo(
    () => JSON.stringify(defaultSelectedReviewers),
    [defaultSelectedReviewers],
  );

  const defaultSelectedSubmitTypeString = useMemo(
    () => JSON.stringify(defaultSelectedSubmitType),
    [defaultSelectedSubmitType],
  );

  const incrementPageCount = () => {
    // @ts-ignore
    setSearchParams((prev) => ({
      ...parse(prev.toString()),
      pageCount: pageCount + 1,
    }));
  };

  const decrementPageCount = () => {
    if (pageCount <= 1) return;
    // @ts-ignore
    setSearchParams((prev) => ({
      ...parse(prev.toString()),
      pageCount: pageCount - 1,
    }));
  };

  const goToFirst = () => {
    if (pageCount <= 1) return;
    // @ts-ignore
    setSearchParams((prev) => ({
      ...parse(prev.toString()),
      pageCount: 1,
    }));
  };

  const goToLast = () => {
    if (pageCount >= totalPages) return;
    // @ts-ignore
    setSearchParams((prev) => ({
      ...parse(prev.toString()),
      pageCount: totalPages,
    }));
  };

  useEffect(() => {
    fetchUserList();
  }, [
    defaultUserId,
    defaultEmail,
    defaultName,
    pageCount,
    defaultSelectedBoiStatusString,
    defaultSelectedReviewersString,
    defaultSelectedSubmitTypeString,
    defaultSelectedPaymentStatus,
  ]);

  return (
    <>
      <div style={{height: windowSize.height, overflow: 'hidden'}}>
        {/* FILTERS */}
        <div style={{height: NAV_BAR_HEIGHT}}>
          <FilterBar
            title={`BOI filing`}
            disableSearchPanel={isLoading}
            onClearFilter={onClearFilter}
            onClickHamburger={openDrawer}
            onClickSearch={onClickSearchHandler}
            init={{
              userId: defaultUserId ?? '',
              email: defaultEmail ?? '',
              name: defaultName ?? '',
            }}>
            <div style={{marginTop: 10}}>
              <Button
                onClick={openFilterModal}
                variant="outlined"
                disabled={isLoading}
                style={{
                  color: themmeColor.grey,
                  borderColor: themmeColor.grey,
                  borderWidth: 1,
                  marginLeft: 10,
                }}>
                Filters
              </Button>
            </div>
            <div style={{marginTop: 10}}>
              <Button
                onClick={exportBoi}
                variant="contained"
                disabled={isLoading}
                style={{
                  color: themmeColor.black,
                  borderColor: themmeColor.grey,
                  borderWidth: 1,
                  marginLeft: 10,
                }}>
                Export
              </Button>
            </div>
          </FilterBar>
        </div>
        {/* MAIN CONTENT */}
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: MAIN_CONTENT_HEIGHT,
            }}>
            <CircularProgress />
          </div>
        ) : (
          <div style={{height: MAIN_CONTENT_HEIGHT}}>
            <TableContainer style={{height: USER_LIST_HEIGHT}}>
              <Table stickyHeader>
                <ListTableHeader tableData={boiListColumnComponentMapper} />
                <ListTableBody
                  recordsArray={recordsArray}
                  tableData={boiListColumnComponentMapper}
                  getRedirectRoute={({flyfin_user_id}) =>
                    `${flyfin_user_id.toString()}/${
                      BoiFilingCenterRoutesParts.DocumentReview
                    }`
                  }
                  flyUserIdFieldName={BOI_FILING_COLUMNS.FLY_USER_ID}
                />
              </Table>
            </TableContainer>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                paddingLeft: 10,
                paddingRight: 10,
                backgroundColor: themmeColor.offWhite,
                height: FOOTER_HEIGHT,
              }}>
              {/* PAGINATION */}
              <Pagination
                isPrev={isPrev}
                isNext={isNext}
                isLoading={isLoading}
                pageCount={pageCount}
                totalPages={totalPages}
                onDecrementPage={decrementPageCount}
                onGotoFirstPage={goToFirst}
                onGotoLastPage={goToLast}
                onIncrementPage={incrementPageCount}
              />
            </div>
          </div>
        )}
      </div>
      {isFilterModalOpen && (
        <BoiListFilterModal
          isOpen={isFilterModalOpen}
          onClose={closeFilterModal}
          tableData={boiListColumnComponentMapper}
        />
      )}
    </>
  );
};

export default BoiFilingList;
