import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Form} from 'src/DesignSystem/Form/Form';

import Text from 'src/DesignSystem/Form/common/Text/Text';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import {selectBusinessDetails} from 'src/store/businessDetails/businessDetails.selector';
import _ from 'lodash';
import {
  OverWriteFormFieldsType,
  useFormData,
} from 'src/CpaCenterV2/hooks/useFormData';
import {patchBusinessDetails} from 'src/appApi';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {
  BusinessDetailsField,
  BusinessType,
} from 'src/store/businessDetails/businessDetails.types';
import {useDispatch} from 'react-redux';
import {fetchBusinessDetails} from 'src/store/businessDetails/businessDetails.actions';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import {getBusinessDetailFormConfig} from './EditBusinesDetails.utils';
import {FORM_INPUT_TYPE} from 'src/DesignSystem/Form/Form.types';
import {BUSINESS_ENTITY_OPTIONS} from './EditBusinessDetails.constants';

interface EditBusinessDetailsProps {
  isOpen: boolean;
  business: ReturnType<typeof selectBusinessDetails>['businesses'][0];
  onClose: () => void;
}

const EditBusinessDetails = ({
  isOpen,
  business,
  onClose,
}: EditBusinessDetailsProps) => {
  const [businessData, setBusinessData] = useState(() =>
    _.cloneDeep(business),
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const {notify} = useNotify();

  const {userId} = useCurrentUserId();

  useEffect(() => {
    if (isOpen) {
      setBusinessData(_.cloneDeep(business));
    }
  }, [business, isOpen]);

  // useEffect(() => {
  //   const {have_ein, entity_type} = businessData;
  //   const newBusinessData = {..._.cloneDeep(business), have_ein, entity_type};
  //   setBusinessData(newBusinessData);
  //   notify(
  //     'All un-saved changes has been discarded',
  //     NotificationType.warning,
  //   );
  // }, [
  //   businessData[BusinessDetailsField.have_ein],
  //   businessData[BusinessDetailsField.entity_type],
  //   business,
  // ]);

  const businessDetailsFormConfig = useMemo(() => {
    return getBusinessDetailFormConfig(
      businessData[BusinessDetailsField.have_ein],
      businessData[BusinessDetailsField.entity_type],
    );
  }, [
    businessData[BusinessDetailsField.have_ein],
    businessData[BusinessDetailsField.entity_type],
  ]);

  const overWriteFields: OverWriteFormFieldsType = useCallback(
    ({fields, setData}) => {
      const haveEinField = fields.find(
        (field) => field.path === BusinessDetailsField.have_ein,
      );
      const businessTypeField = fields.find(
        (field) => field.path === BusinessDetailsField.entity_type,
      );
      if (haveEinField && businessTypeField) {
        if (
          haveEinField.value === false &&
          businessTypeField.inputType === FORM_INPUT_TYPE.SingleSelect
        ) {
          businessTypeField.options = BUSINESS_ENTITY_OPTIONS.filter(
            (option) => option.value === BusinessType.SOLE_PROPRIETORSHIP,
          );
        }
        haveEinField.onChangeValue = (newValue: boolean) => {
          setData((prev: any) => {
            let newState = {..._.set(prev, haveEinField.path, newValue)};
            if (newValue === false) {
              newState = _.set(
                newState,
                businessTypeField.path,
                BusinessType.SOLE_PROPRIETORSHIP,
              );
            }
            return newState;
          });
        };
      }
      return fields;
    },
    [],
  );

  const {fields, areAllFieldsValid} = useFormData({
    config: businessDetailsFormConfig,
    data: businessData,
    setData: setBusinessData,
    overWriteFields,
  });

  const updateBusinessDetails = async () => {
    try {
      setLoading(true);
      await patchBusinessDetails({
        businessData,
        fly_user_id: userId,
        businessId: businessData[BusinessDetailsField.id],
      });
      await dispatch(fetchBusinessDetails(userId));
      onClose();
      notify(
        'Business details updated successfully',
        NotificationType.success,
      );
    } catch (e) {
      notify(`Something went wrong ${e}`, NotificationType.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PopUp
      style={{
        minWidth: 500,
        maxWidth: '70vw',
      }}
      isOpen={isOpen}
      primaryButtonTitle="Update"
      primaryButtonDisabled={!areAllFieldsValid || loading}
      primaryButtonOnClick={updateBusinessDetails}
      secondaryButtonTitle="Close"
      secondaryButtonOnClick={onClose}
      secondaryButtonDisabled={loading}
      onClose={onClose}>
      <Text
        type={Text.TEXT_TYPES.L}
        text="Edit business details"
        fontWeight={Text.FONT_WEIGHTS.SemiBold}
      />
      <div style={{maxHeight: '60vh', overflowY: 'auto'}}>
        {fields.map((field) => {
          return <Form {...field} />;
        })}
      </div>
    </PopUp>
  );
};

export default EditBusinessDetails;
