import {AuthorType} from 'src/constants/constants';
import {
  QueryResolutionModel,
  QueryStatus,
  QueryType,
  ReduxQueryType,
} from './queries.reducer';

export const isQueryResolvedByCpaOrTerminallyResolved = (
  query: ReduxQueryType,
) => {
  return [QueryStatus.CPA_RESOLVED, QueryStatus.RESOLVED].includes(
    query.queryStatus,
  );
};

export const getQueryResolutionModel = (
  queryType: QueryType,
  initiatedBy: AuthorType,
) => {
  if (queryType === QueryType.TAX_FILING && initiatedBy === AuthorType.CPA) {
    return QueryResolutionModel.TAX_FILING;
  }
  return QueryResolutionModel.OTHERS;
};
