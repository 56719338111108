import React from 'react';
import {useSelector} from 'react-redux';
import {TaxProfileSubsection} from 'src/constants/constants';
import {
  FIELD_ID,
  TAX_PROFILE_FIELDS,
} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import SimpleKeyValueField from 'src/CpaCenterV2/TaxProfileSections/common/SimpleKeyValueField';
import SubSectionBox from 'src/CpaCenterV2/TaxProfileSections/common/SubSectionBox';
import {ReduxStateType} from 'src/store/store';
import {
  selectTaxProfile,
  selectTaxProfileAnswer,
} from 'src/store/taxProfile/taxProfile.selector';

interface UserDetailsProps {
  style?: React.CSSProperties;
}

const UserDetails = ({style}: UserDetailsProps) => {
  const {loaded} = useSelector(selectTaxProfile);

  const legalName = useSelector((state: ReduxStateType) => {
    return selectTaxProfileAnswer(state, TAX_PROFILE_FIELDS.LEGAL_NAME);
  });
  const ssn = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TAX_PROFILE_FIELDS.SSN),
  );
  const dob = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TAX_PROFILE_FIELDS.DOB),
  );
  const filingStatus = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TAX_PROFILE_FIELDS.FILING_STATUS),
  );

  const userClaimedDependent = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TAX_PROFILE_FIELDS.CLAIM_YOU_DEPENDENT),
  );
  const dependent = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TAX_PROFILE_FIELDS.DEPENDENT),
  );
  const phoneNumber =
    useSelector((state: ReduxStateType) =>
      selectTaxProfileAnswer(state, TAX_PROFILE_FIELDS.PHONE_NUMBER),
    ) ?? '';

  return (
    <SubSectionBox
      name="User details"
      style={style}
      taxprofileSubsection={TaxProfileSubsection.UserDetails}>
      <SimpleKeyValueField
        name="First name & middle initial"
        style={{marginBottom: 12}}
        value={legalName?.[FIELD_ID.FIRST_NAME]}
        loading={!loaded}
      />
      <SimpleKeyValueField
        name="Last name"
        style={{marginBottom: 12}}
        value={legalName?.[FIELD_ID.LAST_NAME]}
        loading={!loaded}
      />
      <SimpleKeyValueField
        style={{marginBottom: 12}}
        name="Social Security Number"
        loading={!loaded}
        value={ssn}
      />
      <SimpleKeyValueField
        style={{marginBottom: 12}}
        name="Date of birth"
        value={dob}
        loading={!loaded}
      />
      <SimpleKeyValueField
        style={{marginBottom: 12}}
        name="Filing status"
        value={filingStatus}
        loading={!loaded}
      />
      <SimpleKeyValueField
        style={{marginBottom: 12}}
        name="Phone number"
        value={phoneNumber}
        loading={!loaded}
      />
      <SimpleKeyValueField
        style={{marginBottom: 12}}
        name="Can someone else claim you as a dependent?"
        value={userClaimedDependent}
        loading={!loaded}
      />
      <SimpleKeyValueField
        style={{marginBottom: 12}}
        name="Can you claim dependent(s)?"
        value={dependent}
        loading={!loaded}
      />
    </SubSectionBox>
  );
};

export default UserDetails;
