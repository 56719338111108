import {Autocomplete, TextField, Typography} from '@mui/material';
import {React, useState} from 'react';
import {themmeColor} from 'src/constants/constants';
import {
  CPA_CENTER_V2_VALID_STEPS_SEQUENCE,
  MANNUALY_NON_ASSIGNABLE_TAX_RETURN_STATUS_IDS,
  TAX_RETURN_STATUS_LABELS,
} from 'src/CpaCenterList/components/cpaList.utils';
import {TAX_FILING_STATUS} from 'src/constants/constants';
import useIsCpaCenterV2 from 'src/CpaCenterV2/hooks/useIsCpaCenterV2';
import PopUp from 'src/DesignSystem/PopUp/PopUp';

interface UpdateReturnStatusProps {
  isOpen: boolean;
  onClose: () => void;
  currentReturnStatusId: TAX_FILING_STATUS;
  loading: boolean;
  onUpdate: (newStatus: TAX_FILING_STATUS) => void;
}

const UpdateReturnStatus = ({
  isOpen,
  onClose,
  currentReturnStatusId,
  loading,
  onUpdate,
}: UpdateReturnStatusProps) => {
  const {isCpaCenterV2} = useIsCpaCenterV2();
  const currentStatus = TAX_RETURN_STATUS_LABELS[currentReturnStatusId];
  const [activeStatus, setActiveStatus] = useState(currentStatus);

  const getValidReturnStatuses = () => {
    let allowedStatus = Object.values(TAX_RETURN_STATUS_LABELS).filter(
      (status) =>
        !MANNUALY_NON_ASSIGNABLE_TAX_RETURN_STATUS_IDS.includes(status.id),
    );
    if (isCpaCenterV2) {
      allowedStatus = allowedStatus.filter((status) =>
        CPA_CENTER_V2_VALID_STEPS_SEQUENCE.includes(status.id),
      );
      allowedStatus.sort(
        (a, b) =>
          CPA_CENTER_V2_VALID_STEPS_SEQUENCE.findIndex((id) => a.id === id) -
          CPA_CENTER_V2_VALID_STEPS_SEQUENCE.findIndex((id) => b.id === id),
      );
    }
    return allowedStatus;
  };

  const taxReturnStatuses = getValidReturnStatuses();
  const handleOnChange = (e: any, v: any) => {
    setActiveStatus(v);
  };

  return (
    <PopUp
      primaryButtonTitle="Yes, Change it"
      primaryButtonOnClick={() => onUpdate(activeStatus.id)}
      primaryButtonDisabled={
        !activeStatus || currentStatus?.id === activeStatus.id || loading
      }
      secondaryButtonTitle="Cancel"
      secondaryButtonOnClick={onClose}
      secondaryButtonDisabled={loading}
      style={{width: 540}}
      isOpen={isOpen}
      onClose={onClose}>
      <Typography fontSize={20} fontWeight="600">
        Change return status
      </Typography>
      <p style={{marginTop: 12, fontSize: 14}}>
        Do you want to change the return status from{' '}
        <strong>{currentStatus?.label}</strong>
      </p>
      <Autocomplete
        onChange={handleOnChange}
        disablePortal
        id="combo-box-demo"
        options={taxReturnStatuses}
        value={activeStatus}
        fullWidth
        style={{marginTop: 16}}
        sx={{width: 300}}
        renderInput={(params) => (
          <TextField {...params} label="Return Status" />
        )}
      />
    </PopUp>
  );
};

export default UpdateReturnStatus;
