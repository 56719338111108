import {TaxProfileSubsection} from 'src/constants/constants';
import {SET_QUERIES} from '../actionNames';
import {IRSCategory} from '../transactions/transactions.constants';
import {ReduxActionType, REDUX_STATUS} from '../types';
import {TaxReturnType} from '../taxReturns/taxReturns.reducer';

export enum QueryType {
  GENERAL = 'GENERAL',
  TXN_RELATED = 'TXN_RELATED',
  TAX_FILING = 'TAX_FILING',
  QTC = 'QTC',
}

export enum QuerySubType {
  WRONG_DOC = 'WRONG_DOC',
  DOC_NOT_CLEAR = 'DOC_NOT_CLEAR',
  INFO_MISMATCH = 'INFO_MISMATCH',
  ADDITIONAL_DOC = 'ADDITIONAL_DOC',
  INCOMPLETE_DOC = 'INCOMPLETE_DOC',
  GENERAL = 'GENERAL',
  TAX_RETURN_WRONG_INFO = 'TAX_RETURN_WRONG_INFO',
  TAX_RETURN_MISSING_INFO = 'TAX_RETURN_MISSING_INFO',
  TAX_PROFILE_CPA = 'TAX_PROFILE_CPA',
  HIGH_VALUE_DEDUCTIONS = 'HIGH_VALUE_DEDUCTIONS',
  DISTRIBUTE_DEDUCTIONS = 'DISTRIBUTE_DEDUCTIONS',
  TAX_PROFILE = 'TAX_PROFILE',
  REVIEW_ESIGN = 'REVIEW_ESIGN',
}

export enum QueryStatus {
  OPEN = 'OPEN',
  SENT = 'SENT',
  RESOLVED = 'RESOLVED',
  DRAFT = 'DRAFT',
  USER_RESOLVED = 'USER_RESOLVED',
  CPA_RESOLVED = 'CPA_RESOLVED',
}

export enum CpaCallStatus {
  NA = 'NA',
  CALL_MESSAGE_SENT = 'CALL_MESSAGE_SENT',
  CALL_SCHEDULED = 'CALL_SCHEDULED',
  CALL_CANCELLED = 'CALL_CANCELLED',
  CALL_OVER = 'CALL_OVER',
}

export enum MessageAuthorType {
  END_USER = 'END_USER',
  CPA = 'CPA',
  SYSTEM = 'SYSTEM',
}

export enum MessageType {
  CHAT = 'CHAT',
  INFO = 'INFO',
  DOC_ATTACHED = 'DOC_ATTACHED',
  TAX_RETURN_WRONG_INFO = 'TAX_RETURN_WRONG_INFO',
  TAX_RETURN_MISSING_INFO = 'TAX_RETURN_MISSING_INFO',
  CALL_INVITE = 'CALL_INVITE',
  CALL_CANCELLATION = 'CALL_CANCELLATION',
  TAX_RETURN_REVIEW = 'TAX_RETURN_REVIEW',
}

export enum MessageSubType {
  RESOLVED = 'RESOLVED',
  REOPENED = 'REOPENED',
}

export enum DerivedMessageType {
  NORMAL = 'NORMAL',
  HISTORY = 'HISTORY',
  DOC_ATTACHED = 'DOC_ATTACHED',
  DRAFT_MESSAGE = 'DRAFT_MESSAGE',
}

export interface QueryDocDataType {
  docId: number;
  docType: string;
  docTypeId: number;
  fileName: string;
}

export interface ReduxMessageType {
  messageId: number;
  message: string;
  messageCreatedAt: Date;
  authorType: MessageAuthorType;
  messageType: MessageType;
  messageSubType: MessageSubType;
  extraData: any;
  isDraft: boolean;
  docId: any;
  filename: any;
  docTypeId: any;
  docType: any;
  authorName: string;
  queryId: number;
  derivedMessageType: DerivedMessageType;
  attachments: {
    filename: string;
    unsigned_link: string;
  }[];
}

export enum DerivedQueryType {
  UPLOAD_NEW_DOCUMENT = 'UPLOAD_NEW_DOCUMENT',
  REUPLOAD_DOCUMENT = 'REUPLOAD_DOCUMENT',
  HIGH_VALUE_DEDUCTION = 'HIGH_VALUE_DEDUCTION',
  TAX_PROFILE_CPA = 'TAX_PROFILE_CPA',
  TAX_PROFILE = 'TAX_PROFILE',
  REVIEW_ESIGN = 'REVIEW_ESIGN',
  DISTRIBUTE_DEDUCTIONS = 'DISTRIBUTE_DEDUCTIONS',
  GENERAL = 'GENERAL',
  UNKNOWN = 'UKNWOWN',
}

interface HighValueDeductions {
  txn_id: number;
  original_irs_category: IRSCategory;
}

export interface ReduxQueryType {
  queryId: number;
  query: string;
  queryCreatedAt: string;
  flyUserId: number;
  appYear: number;
  queryType: QueryType;
  querySubtype: QuerySubType;
  taxProfileSubsection: TaxProfileSubsection;
  queryStatus: QueryStatus;
  queryStatusUpdatedAt: string;
  callStatus: CpaCallStatus;
  taxReturnFormSection: any;
  startTime: any;
  endTime: any;
  calendlyEventUuid: any;
  calendlyInviteeUuid: any;
  name: string;
  email: string;
  createdDate: string;
  assigneeEmail: string;
  messages: ReduxMessageType[];
  docData: null | QueryDocDataType;
  derivedQueryType: DerivedQueryType;
  highValueDeductions: HighValueDeductions[];
  hasUserRead: boolean;
  biz_return_id: number | null;
  assigned_return_type: TaxReturnType | null;
}

export enum QueryResolutionModel {
  TAX_FILING = 'TAX_FILING',
  OTHERS = 'OTHERS',
}

const initialState = {
  loaded: false,
  status: REDUX_STATUS.INIT,
  count: 0,
  queries: [] as ReduxQueryType[],
  queryMap: {} as {[key: number]: ReduxQueryType},
  messageMap: {} as {[key: number]: ReduxMessageType},
};

export const queries = (state = initialState, action: ReduxActionType) => {
  switch (action.type) {
    case SET_QUERIES:
      return {
        ...state,
        ...action.payload,
        loaded: true,
        status: REDUX_STATUS.SUCCESS,
      };
  }
  return state;
};

export type QueriesStateType = typeof initialState;
