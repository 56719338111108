import {Typography} from '@mui/material';
import React from 'react';
import {useSelector} from 'react-redux';
import {
  ALL_FORM_TYPES,
  TaxProfileSubsection,
  themmeColor,
} from 'src/constants/constants';
import {TAX_PROFILE_FIELDS} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import DocumentPointer from 'src/CpaCenterV2/common/DocumentPointer/DocumentPointer';
import {selectDocuments} from 'src/store/documents/documents.selector';
import {
  MortgageTypes,
  TaxFormFieldIds,
} from 'src/store/taxForms/taxForms.constants';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {selectTaxProfileAnswer} from 'src/store/taxProfile/taxProfile.selector';
import SimpleKeyValueField from '../../common/SimpleKeyValueField';
import SubSectionBox from '../../common/SubSectionBox';
import CommonDeductionTable from './CommonDeductionTable';
import {DEDUCTION_OPTIONS} from '../Deductions.constants';
import {
  TaxProfileSummaryVariant,
  useTaxProfileSummaryFilter,
} from '../../common/TaxProfileSummaryFilter/useTaxProfileSummaryFilter';

const PersonalDeductions = () => {
  const deductionOption = useSelector((state) =>
    selectTaxProfileAnswer(state, TAX_PROFILE_FIELDS.DEDUCTION_OPTION),
  );
  const {taxForms} = useSelector(selectTaxFormsReducer);
  const {documents} = useSelector(selectDocuments);
  const {summaryVariant} = useTaxProfileSummaryFilter();

  const charityForms = taxForms.filter(
    (tf) => tf.formType === ALL_FORM_TYPES.CHARITY_CONTRIBUTION_RECEIPT,
  );

  const homeMortgageInterest = taxForms.filter(
    (tf) =>
      tf.formType === ALL_FORM_TYPES.FORM_1098_MIS &&
      tf.formData[TaxFormFieldIds.MORTGAGE_TYPE] === MortgageTypes.HOME,
  );

  return (
    <SubSectionBox
      name="Personal deductions"
      taxprofileSubsection={TaxProfileSubsection.PersonalDeductions}>
      <div
        style={
          summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
            ? {}
            : {display: 'flex', flex: 1, justifyContent: 'space-between'}
        }>
        <div
          style={
            summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
              ? {}
              : {flex: 0.48}
          }>
          <SimpleKeyValueField
            name="Selected deduction type"
            value={deductionOption}
          />
          {deductionOption === DEDUCTION_OPTIONS.SCHEDULE_A && (
            <>
              <Typography style={{fontSize: 13, color: themmeColor.black60}}>
                Charity document
              </Typography>
              {charityForms.map((tf) => (
                <DocumentPointer
                  dontShowStatus
                  doc={documents.find((doc) => doc.docId === tf.docId)}
                  taxform={tf}
                  border
                />
              ))}
              <Typography style={{fontSize: 13, color: themmeColor.black60}}>
                Mortgage document (Home)
              </Typography>
              {homeMortgageInterest.map((tf) => (
                <DocumentPointer
                  dontShowStatus
                  doc={documents.find((doc) => doc.docId === tf.docId)}
                  taxform={tf}
                  border
                />
              ))}
            </>
          )}
        </div>
        <div
          style={
            summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
              ? {marginTop: 20}
              : {flex: 0.48}
          }>
          {deductionOption === DEDUCTION_OPTIONS.SCHEDULE_A && (
            <CommonDeductionTable
              taxProfileSubsection={TaxProfileSubsection.PersonalDeductions}
            />
          )}
        </div>
      </div>
    </SubSectionBox>
  );
};

export default PersonalDeductions;
