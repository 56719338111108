import {
  TaxProfileSection,
  TaxProfileSectionToSubsectionMapping,
  TaxProfileSubsection,
} from 'src/constants/constants';
import {BusinessDetails} from 'src/store/businessDetails/businessDetails.reducer';
import {
  TaxReturn,
  TaxReturnType,
} from 'src/store/taxReturns/taxReturns.reducer';

export const getTaxProfileSectionFromSubsection = (
  subsection: TaxProfileSubsection,
) => {
  const sections = Object.keys(TaxProfileSectionToSubsectionMapping);
  let targetSection = 'batman';
  sections.forEach((section: TaxProfileSection) => {
    if (TaxProfileSectionToSubsectionMapping[section].includes(subsection)) {
      targetSection = section;
    }
  });
  return targetSection as TaxProfileSection;
};

export const getFieldValue = (value: any) => {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }
  if (typeof value === 'number') {
    return value;
  }
  if (!value) {
    return 'NA';
  }
  return value;
};

export const getReturnName = (
  taxReturn: TaxReturn,
  businesses: BusinessDetails[],
) => {
  if (taxReturn.return_type === TaxReturnType.INDIVIDUAL) {
    return 'Individual Return';
  } else if (taxReturn.return_type === TaxReturnType.BUSINESS) {
    const business = businesses.find(
      (data) => data.id === taxReturn.business_id,
    );
    return `Business Return - ${business?.name} (${business?.entityType})`;
  }

  return '';
};
