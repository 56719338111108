import {getBankAccountDetails} from 'src/appApi';
import {SET_BANK_DETAILS} from '../actionNames';
import {BankDetailsStateType} from './bankDetails.reducer';
import {AppReducerStates, NotificationType} from '../app/app.reducer';
import {setAppState} from '../app/app.actions';
import {selectActiveYear} from '../globals/globals.selector';
import {getStateType} from '../store';

const setBankDetails = (
  payload: Pick<BankDetailsStateType, 'bankDetails'>,
) => {
  return {
    type: SET_BANK_DETAILS,
    payload,
  };
};

export const fetchBankDetails = (userId: number) => {
  return async (dispatch: any, getState: getStateType) => {
    const activeYear = selectActiveYear(getState());
    try {
      const {data} = (await getBankAccountDetails({
        fly_user_id: userId,
        year: activeYear,
      })) as any;
      dispatch(setBankDetails({bankDetails: data}));
    } catch (e) {
      dispatch(
        setAppState(AppReducerStates.notifcationStatus, {
          isVisible: true,
          message: `Failed to fetch bank details (${e})`,
          type: NotificationType.error,
        }),
      );
    }
  };
};
