import {ReturnSummaryKeys} from 'src/constants/constants';
import {
  ReturnSummaryInputFieldProps,
  ReturnSummaryInputType,
} from './ReviewSummary.types';

export const emptyIndividualTaxReturnSumary: ReturnSummaryInputFieldProps[] = [
  {
    type: ReturnSummaryInputType.TEXT_INPUT,
    key: ReturnSummaryKeys.agi,
    value: null,
  },
  {
    type: ReturnSummaryInputType.DROPDOWN,
    key: null,
    options: [
      ReturnSummaryKeys.standard_deduction,
      ReturnSummaryKeys.itemized_deduction,
    ],
    value: null,
  },
  {
    type: ReturnSummaryInputType.TEXT_INPUT,
    key: ReturnSummaryKeys.taxable_income,
    value: null,
  },
  {
    type: ReturnSummaryInputType.TEXT_INPUT,
    key: ReturnSummaryKeys.income_tax,
    value: null,
  },
  {
    type: ReturnSummaryInputType.TEXT_INPUT,
    key: ReturnSummaryKeys.self_employment_tax,
    value: null,
  },
  {
    type: ReturnSummaryInputType.TEXT_INPUT,
    key: ReturnSummaryKeys.income_tax_withheld,
    value: null,
  },
  {
    type: ReturnSummaryInputType.DROPDOWN,
    key: null,
    options: [
      ReturnSummaryKeys.tax_due_amount,
      ReturnSummaryKeys.tax_refund_amount,
    ],
    value: null,
    valueMandatory: true,
  },
];

export const emptySCorpFederalTaxReturnSumary: ReturnSummaryInputFieldProps[] =
  [
    {
      type: ReturnSummaryInputType.DROPDOWN,
      key: null,
      options: [
        ReturnSummaryKeys.ord_bus_income,
        ReturnSummaryKeys.ord_bus_loss,
      ],
      value: null,
      valueMandatory: true,
    },
    {
      type: ReturnSummaryInputType.DROPDOWN,
      key: null,
      options: [
        ReturnSummaryKeys.rental_estate_income,
        ReturnSummaryKeys.rental_estate_loss,
      ],
      value: null,
      valueMandatory: true,
    },
    {
      type: ReturnSummaryInputType.DROPDOWN,
      key: null,
      options: [
        ReturnSummaryKeys.other_rental_income,
        ReturnSummaryKeys.other_rental_loss,
      ],
      value: null,
      valueMandatory: true,
    },
    {
      type: ReturnSummaryInputType.TEXT_INPUT,
      key: ReturnSummaryKeys.interest_income,
      value: null,
    },
    {
      type: ReturnSummaryInputType.TEXT_INPUT,
      key: ReturnSummaryKeys.dividends,
      value: null,
    },
    {
      type: ReturnSummaryInputType.TEXT_INPUT,
      key: ReturnSummaryKeys.royalties,
      value: null,
    },
    {
      type: ReturnSummaryInputType.DROPDOWN,
      key: null,
      options: [
        ReturnSummaryKeys.net_short_term_gain,
        ReturnSummaryKeys.net_short_term_loss,
      ],
      value: null,
      valueMandatory: true,
    },
    {
      type: ReturnSummaryInputType.DROPDOWN,
      key: null,
      options: [
        ReturnSummaryKeys.net_long_term_gain,
        ReturnSummaryKeys.net_long_term_loss,
      ],
      value: null,
      valueMandatory: true,
    },
    {
      type: ReturnSummaryInputType.DROPDOWN,
      key: null,
      options: [
        ReturnSummaryKeys.sec_1231_gain,
        ReturnSummaryKeys.sec_1231_loss,
      ],
      value: null,
      valueMandatory: true,
    },
    {
      type: ReturnSummaryInputType.DROPDOWN,
      key: null,
      options: [ReturnSummaryKeys.other_income, ReturnSummaryKeys.other_loss],
      value: null,
      valueMandatory: true,
    },
  ];

export const emptyPartnershipFederalTaxReturnSumary: ReturnSummaryInputFieldProps[] =
  [
    {
      type: ReturnSummaryInputType.DROPDOWN,
      key: null,
      options: [
        ReturnSummaryKeys.ord_bus_income,
        ReturnSummaryKeys.ord_bus_loss,
      ],
      value: null,
      valueMandatory: true,
    },
    {
      type: ReturnSummaryInputType.DROPDOWN,
      key: null,
      options: [
        ReturnSummaryKeys.rental_estate_income,
        ReturnSummaryKeys.rental_estate_loss,
      ],
      value: null,
      valueMandatory: true,
    },
    {
      type: ReturnSummaryInputType.DROPDOWN,
      key: null,
      options: [
        ReturnSummaryKeys.other_rental_income,
        ReturnSummaryKeys.other_rental_loss,
      ],
      value: null,
      valueMandatory: true,
    },
    {
      type: ReturnSummaryInputType.TEXT_INPUT,
      key: ReturnSummaryKeys.guaranteed_payments,
      value: null,
    },
    {
      type: ReturnSummaryInputType.TEXT_INPUT,
      key: ReturnSummaryKeys.interest_income,
      value: null,
    },
    {
      type: ReturnSummaryInputType.TEXT_INPUT,
      key: ReturnSummaryKeys.dividends,
      value: null,
    },
    {
      type: ReturnSummaryInputType.TEXT_INPUT,
      key: ReturnSummaryKeys.royalties,
      value: null,
    },
    {
      type: ReturnSummaryInputType.DROPDOWN,
      key: null,
      options: [
        ReturnSummaryKeys.net_short_term_gain,
        ReturnSummaryKeys.net_short_term_loss,
      ],
      value: null,
      valueMandatory: true,
    },
    {
      type: ReturnSummaryInputType.DROPDOWN,
      key: null,
      options: [
        ReturnSummaryKeys.net_long_term_gain,
        ReturnSummaryKeys.net_long_term_loss,
      ],
      value: null,
      valueMandatory: true,
    },
    {
      type: ReturnSummaryInputType.DROPDOWN,
      key: null,
      options: [
        ReturnSummaryKeys.sec_1231_gain,
        ReturnSummaryKeys.sec_1231_loss,
      ],
      value: null,
      valueMandatory: true,
    },
    {
      type: ReturnSummaryInputType.DROPDOWN,
      key: null,
      options: [ReturnSummaryKeys.other_income, ReturnSummaryKeys.other_loss],
      value: null,
      valueMandatory: true,
    },
  ];

export const emptyCCorpFederalTaxReturnSumary: ReturnSummaryInputFieldProps[] =
  [
    {
      type: ReturnSummaryInputType.TEXT_INPUT,
      key: ReturnSummaryKeys.gross_income,
      value: null,
    },
    {
      type: ReturnSummaryInputType.TEXT_INPUT,
      key: ReturnSummaryKeys.cost_of_goods,
      value: null,
    },
    {
      type: ReturnSummaryInputType.TEXT_INPUT,
      key: ReturnSummaryKeys.other_income,
      value: null,
    },
    {
      type: ReturnSummaryInputType.DROPDOWN,
      key: null,
      options: [ReturnSummaryKeys.total_income, ReturnSummaryKeys.total_loss],
      value: null,
      valueMandatory: true,
    },
    {
      type: ReturnSummaryInputType.TEXT_INPUT,
      key: ReturnSummaryKeys.total_deductions,
      value: null,
    },
    {
      type: ReturnSummaryInputType.TEXT_INPUT,
      key: ReturnSummaryKeys.taxable_income,
      value: null,
    },
    {
      type: ReturnSummaryInputType.TEXT_INPUT,
      key: ReturnSummaryKeys.total_tax,
      value: null,
    },
    {
      type: ReturnSummaryInputType.TEXT_INPUT,
      key: ReturnSummaryKeys.payments_and_credits,
      value: null,
    },
    {
      type: ReturnSummaryInputType.TEXT_INPUT,
      key: ReturnSummaryKeys.tax_due_amount,
      value: null,
    },
    {
      type: ReturnSummaryInputType.TEXT_INPUT,
      key: ReturnSummaryKeys.tax_refund_amount,
      value: null,
    },
  ];

export const emptyCCorpStateTaxReturnSumary: ReturnSummaryInputFieldProps[] = [
  {
    type: ReturnSummaryInputType.TEXT_INPUT,
    key: ReturnSummaryKeys.dividends,
    value: null,
  },
  {
    type: ReturnSummaryInputType.TEXT_INPUT,
    key: ReturnSummaryKeys.royalties,
    value: null,
  },
  {
    type: ReturnSummaryInputType.DROPDOWN,
    key: null,
    options: [ReturnSummaryKeys.tax_to_pay, ReturnSummaryKeys.tax_refund],
    value: null,
    valueMandatory: true,
  },
];

export const emptyPartnershipSCorpStateTaxReturnSummary: ReturnSummaryInputFieldProps[] =
  [
    {
      type: ReturnSummaryInputType.TEXT_INPUT,
      key: ReturnSummaryKeys.franchise_tax,
      value: null,
    },
  ];
