import React from 'react';
import {Route, Routes} from 'react-router-dom';
import CpaCenterDrawer from 'src/common/Drawer/CpaCenterDrawer';
import {CpaCenterV2RouteParts, RouteName} from 'src/constants/routeName';
import CpaCenterList from 'src/CpaCenterList/CpaCenterList';
import UserQueryRouter from 'src/UserQueries/UserQueryRouter';
import CpaCenterV2 from './CpaCenterV2';
import BoiFilingCenterRouter from 'src/BoiFilingCenter/BoiFilingCenterRouter';

const CpaCenterV2Router = () => {
  return (
    <Routes>
      <Route element={<CpaCenterDrawer />}>
        <Route path={RouteName.TaxFiling} element={<CpaCenterList />} />
        <Route
          path={`/${RouteName.UserQueries.UserQueries}/*`}
          element={<UserQueryRouter />}
        />
        <Route
          path={`${RouteName.TaxFiling}/${CpaCenterV2RouteParts.UserId}/*`}
          element={<CpaCenterV2 />}
        />
        <Route path={`${RouteName.TaxFiling}/*`} element={<CpaCenterV2 />} />
        <Route
          path={`${RouteName.BoiFilingCenter}/*`}
          element={<BoiFilingCenterRouter />}
        />
        <Route path={'*'} element={<h1>Route Not found</h1>} />
      </Route>
    </Routes>
  );
};

export default CpaCenterV2Router;
