import React from 'react';
import {useSelector} from 'react-redux';
import {CurrencyNumberFormat} from 'src/common/utils';
import {TaxProfileSubsection} from 'src/constants/constants';
import Box from 'src/DesignSystem/Box/Box';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import SubSectionBox from '../../common/SubSectionBox';
import {
  TaxProfileSubsectionToFormTypeMap,
  mapTaxProfileSectionToIncomeSource,
} from '../Income.constants';
import SingleRentalIncome from './SingleRentalIncome';
import {getTotalIncomeForFormType} from './TaxFormIncomeCard/TaxFormIncomeCard.utils';
import {selectTaxProfileAnswer} from 'src/store/taxProfile/taxProfile.selector';
import {ReduxStateType} from 'src/store/store';
import {TAX_PROFILE_FIELDS} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import {
  TaxProfileSummaryVariant,
  useTaxProfileSummaryFilter,
} from '../../common/TaxProfileSummaryFilter/useTaxProfileSummaryFilter';

const RentalIncome = () => {
  const subsection = TaxProfileSubsection.RentalIncome;

  const {taxForms} = useSelector(selectTaxFormsReducer);
  const userAddedIncomes =
    useSelector((state: ReduxStateType) =>
      selectTaxProfileAnswer(
        state,
        TAX_PROFILE_FIELDS.TAX_PROFILE_INCOME_SOURCES,
      ),
    ) ?? [];
  const {summaryVariant} = useTaxProfileSummaryFilter();

  const formTypes = TaxProfileSubsectionToFormTypeMap[subsection];

  const incomeForms = taxForms.filter((tf) => formTypes.includes(tf.formType));

  const totalIncome = incomeForms
    .map((tf) => getTotalIncomeForFormType(tf).amount)
    .reduce((acc, curr) => acc + curr, 0);

  return (
    <SubSectionBox
      name={`Rental income (${CurrencyNumberFormat(totalIncome)})`}
      taxprofileSubsection={subsection}
      querieNotAllowed={
        !userAddedIncomes.includes(
          mapTaxProfileSectionToIncomeSource[subsection] ?? '',
        )
      }>
      <Box style={{flexWrap: 'wrap', justifyContent: 'space-between'}}>
        {incomeForms.map((form, index) => (
          <div
            style={
              summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
                ? {}
                : {width: '48%'}
            }>
            <SingleRentalIncome
              sectionName={`Rental Income ${index + 1}`}
              taxform={form}
            />
          </div>
        ))}
      </Box>
    </SubSectionBox>
  );
};

export default RentalIncome;
