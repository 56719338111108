import React from 'react';
import {Typography} from '@mui/material';
import {TaxProfileSubsection, themmeColor} from 'src/constants/constants';
import useTaxProfileSummary from '../useTaxProfileSummary';
import KeyValueTable from '../../KeyValueTable/KeyValueTable';
import {CurrencyNumberFormat} from 'src/common/utils';
import ExternalIcon from 'src/icons/ExternalIcon';
import useCpaCenterV2Navigation from 'src/CpaCenterV2/hooks/useCpaCenterV2Navigation';
import SimpleArrayField from 'src/CpaCenterV2/TaxProfileSections/common/SimpleArrayField';
import Box from 'src/DesignSystem/Box/Box';
import ErrorIcon from 'src/icons/ErrorIcon';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

interface TaxProfileSummaryProps {
  allowNavigateToTaxProfileReview?: boolean;
}

const TaxProfileSummary = ({
  allowNavigateToTaxProfileReview = false,
}: TaxProfileSummaryProps) => {
  const {activeYear} = useActiveYear();
  const {
    fullName,
    filingStatus,
    livingStates,
    earningStates,
    dependentDetails,
    businesses,
    incomeSummary,
    getApplicableDeductions,
    getFields,
    skipLastYear1040Reason,
  } = useTaxProfileSummary();

  const {navigateToTaxProfileReview} = useCpaCenterV2Navigation();

  return (
    <div style={{padding: 32}}>
      <Box
        backgroundColor={themmeColor.cpaCenterV2Bg}
        style={{paddingInline: 16, paddingBlock: 12, marginBottom: 12}}>
        <div style={{marginRight: 8, display: 'flex'}}>
          <ErrorIcon color={themmeColor.black} />
        </div>
        <Typography>
          1040 not uploaded: <strong>{skipLastYear1040Reason}</strong>
        </Typography>
      </Box>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}>
        <div style={{marginRight: 32}}>
          {/* full name */}
          <Typography
            style={{fontSize: 16, fontWeight: 600, color: themmeColor.black}}>
            {fullName}
          </Typography>

          {/* filing status */}
          <Typography style={{fontSize: 16, color: themmeColor.black70}}>
            {filingStatus}
          </Typography>
        </div>
        {allowNavigateToTaxProfileReview && (
          <div onClick={navigateToTaxProfileReview}>
            <ExternalIcon />
          </div>
        )}
      </div>

      {/* states lived in */}
      <Typography
        style={{
          fontSize: 11,
          color: themmeColor.silver,
          textTransform: 'uppercase',
          fontWeight: 600,
          marginTop: 32,
        }}>
        States lived in {activeYear}
      </Typography>
      {livingStates.map((state) => (
        <Typography
          style={{fontSize: 16, color: themmeColor.black, marginTop: 8}}>
          {state}
        </Typography>
      ))}
      {/* earning states */}
      <Typography
        style={{
          fontSize: 11,
          color: themmeColor.silver,
          textTransform: 'uppercase',
          fontWeight: 600,
          marginTop: 32,
        }}>
        State with income in {activeYear}
      </Typography>
      {earningStates.map((state) => (
        <Typography
          style={{fontSize: 16, color: themmeColor.black, marginTop: 8}}>
          {state}
        </Typography>
      ))}
      {/* Dependants  */}
      <Typography
        style={{
          fontSize: 11,
          color: themmeColor.silver,
          textTransform: 'uppercase',
          fontWeight: 600,
          marginTop: 32,
        }}>
        Dependants
      </Typography>
      {dependentDetails.map(({name, age}) => (
        <Typography
          style={{
            fontSize: 16,
            color: themmeColor.black,
            fontWeight: 600,
            marginTop: 8,
            display: 'flex',
          }}>
          {name} - Age {age}
        </Typography>
      ))}

      {/* Business Details */}
      <Typography
        style={{
          fontSize: 11,
          color: themmeColor.silver,
          textTransform: 'uppercase',
          fontWeight: 600,
          marginTop: 32,
        }}>
        Businesses
      </Typography>
      {businesses.map((business) => (
        <Typography
          style={{
            fontSize: 16,
            color: themmeColor.black,
            fontWeight: 600,
            marginTop: 8,
          }}>
          {business.entity_type} - {business.name}
        </Typography>
      ))}
      {/* Income Summary */}
      <Typography
        style={{
          fontSize: 11,
          color: themmeColor.silver,
          textTransform: 'uppercase',
          fontWeight: 600,
          marginTop: 32,
        }}>
        Income Summary
      </Typography>
      <KeyValueTable
        data={incomeSummary}
        keys={[
          {
            getValue: ({type}) => type,
            textStyle: {
              fontSize: 11,
            },
          },
          {
            getValue: ({payerName}) => payerName,
            textStyle: {
              fontSize: 11,
            },
            cellStyle: {
              marginInline: 4,
            },
          },
          {
            getValue: ({amount = undefined}) =>
              amount ? CurrencyNumberFormat(amount) : 'NA',
            cellStyle: {
              justifyContent: 'flex-end',
              display: 'flex',
            },
            textStyle: {fontWeight: 600, fontSize: 11},
          },
        ]}
      />
      <div style={{marginTop: 32}}>
        <SimpleArrayField
          name="Deductions Applicable"
          value={getApplicableDeductions()}
        />
      </div>
      <div style={{marginTop: 32}}>
        <SimpleArrayField
          name="Deductions classified"
          value={getFields(TaxProfileSubsection.AllOtherDeductions).map(
            (item) => `${item.label} (${item.value})`,
          )}
        />
      </div>
    </div>
  );
};

export default TaxProfileSummary;
