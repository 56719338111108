import {DOCUMENT_TYPES, TaxProfileSubsection} from 'src/constants/constants';
import {
  DerivedQueryType,
  ReduxQueryType,
} from 'src/store/queries/queries.reducer';
import {QueryFilterKeys, QueryFilterType} from './UserQueries.types';

export const getFilteredQueries = (queries: ReduxQueryType[]) => {
  return queries.filter((query) =>
    [
      DerivedQueryType.UPLOAD_NEW_DOCUMENT,
      DerivedQueryType.REUPLOAD_DOCUMENT,
      DerivedQueryType.TAX_PROFILE_CPA,
      DerivedQueryType.HIGH_VALUE_DEDUCTION,
      DerivedQueryType.TAX_PROFILE,
      DerivedQueryType.GENERAL,
      DerivedQueryType.REVIEW_ESIGN,
      DerivedQueryType.DISTRIBUTE_DEDUCTIONS,
    ].includes(query.derivedQueryType),
  );
};

export const getQueriesFilters = (queries: ReduxQueryType[]) => {
  const filters: QueryFilterType[] = [];
  const docTypesSet = new Set<DOCUMENT_TYPES>();
  const taxProfileSubsectionSet = new Set<TaxProfileSubsection>();
  let isAnyHighValueDeductionQuery = false;
  let isAnyUserTaxProfileQuery = false;
  let isAnyGeneralQuery = false;
  let isAnyReviewEsignQuery = false;
  let isAnyDistributeDeductionsQuery = false;
  getFilteredQueries(queries).forEach(
    ({derivedQueryType, docData, taxProfileSubsection}) => {
      if (
        [
          DerivedQueryType.UPLOAD_NEW_DOCUMENT,
          DerivedQueryType.REUPLOAD_DOCUMENT,
        ].includes(derivedQueryType)
      ) {
        docTypesSet.add(docData?.docType);
      }
      if (derivedQueryType === DerivedQueryType.TAX_PROFILE_CPA) {
        taxProfileSubsectionSet.add(taxProfileSubsection);
      }
      if (derivedQueryType === DerivedQueryType.HIGH_VALUE_DEDUCTION) {
        isAnyHighValueDeductionQuery = true;
      }
      if (derivedQueryType === DerivedQueryType.TAX_PROFILE) {
        isAnyUserTaxProfileQuery = true;
      }
      if (derivedQueryType === DerivedQueryType.GENERAL) {
        isAnyGeneralQuery = true;
      }
      if (derivedQueryType === DerivedQueryType.REVIEW_ESIGN) {
        isAnyReviewEsignQuery = true;
      }
      if (derivedQueryType === DerivedQueryType.DISTRIBUTE_DEDUCTIONS) {
        isAnyDistributeDeductionsQuery = true;
      }
    },
  );
  taxProfileSubsectionSet.forEach((taxProfileSubsection) => {
    filters.push({
      [QueryFilterKeys.subsection]: taxProfileSubsection,
    });
  });
  docTypesSet.forEach((docType) => {
    filters.push({
      [QueryFilterKeys.docType]: docType,
    });
  });
  if (isAnyHighValueDeductionQuery) {
    filters.push({
      [QueryFilterKeys.highValueDeduction]:
        DerivedQueryType.HIGH_VALUE_DEDUCTION,
    });
  }
  if (isAnyUserTaxProfileQuery) {
    filters.push({
      [QueryFilterKeys.userTaxProfile]: DerivedQueryType.TAX_PROFILE,
    });
  }
  if (isAnyGeneralQuery) {
    filters.push({
      [QueryFilterKeys.general]: DerivedQueryType.GENERAL,
    });
  }
  if (isAnyReviewEsignQuery) {
    filters.push({
      [QueryFilterKeys.reviewEsign]: DerivedQueryType.REVIEW_ESIGN,
    });
  }
  if (isAnyDistributeDeductionsQuery) {
    filters.push({
      [QueryFilterKeys.distributeDeductions]:
        DerivedQueryType.DISTRIBUTE_DEDUCTIONS,
    });
  }
  return filters;
};
