import React, {ChangeEventHandler, useCallback} from 'react';
import InputWrapper, {
  InputWrapperBaseType,
} from '../components/InputWrapper/InputWrapper';

interface TextFieldProps extends InputWrapperBaseType {
  placeholder?: string;
  onChangeValue: (newText: string) => void;
  value: string | readonly string[] | number | undefined;
}

const TextField = ({
  onChangeValue: onChangeValue,
  ...rest
}: TextFieldProps) => {
  const _onChange = useCallback(
    (e: Parameters<ChangeEventHandler<HTMLInputElement>>[0]) => {
      if (onChangeValue) {
        onChangeValue(e.target.value);
      }
    },
    [onChangeValue],
  );

  return (
    <InputWrapper
      {...rest}
      actualValue={rest.value}
      type="text"
      onChange={_onChange}
    />
  );
};

export default TextField;
