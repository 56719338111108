import React from 'react';
import {useSelector} from 'react-redux';
import {ALL_FORM_TYPES, TaxProfileSubsection} from 'src/constants/constants';
import SubSectionBox from 'src/CpaCenterV2/TaxProfileSections/common/SubSectionBox';
import Skeleton from 'src/icons/Skeleton';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.constants';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {
  selectTaxProfile,
  selectTaxProfileAnswer,
} from 'src/store/taxProfile/taxProfile.selector';
import DependentCard from './components/DependentCard';
import {ReduxStateType} from 'src/store/store';
import {TAX_PROFILE_FIELDS} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import {FILING_STATUS_ANSWER} from 'src/CpaCenterV2/TaxProfileSections/TaxProfileSections.constants';

interface DependentDetailsProps {
  style?: React.CSSProperties;
}

const DependentDetails = ({style}: DependentDetailsProps) => {
  const {loaded} = useSelector(selectTaxProfile);

  const {taxForms} = useSelector(selectTaxFormsReducer);

  const filingStatus = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TAX_PROFILE_FIELDS.FILING_STATUS),
  );
  const canSomeoneElseClaimSpouseAsDependent = useSelector(
    (state: ReduxStateType) =>
      selectTaxProfileAnswer(
        state,
        TAX_PROFILE_FIELDS.SPOUSE_CLAIMED_DEPENDENT,
      ),
  );
  const userClaimedDependent = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TAX_PROFILE_FIELDS.CLAIM_YOU_DEPENDENT),
  );
  const hasDependents = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TAX_PROFILE_FIELDS.DEPENDENT),
  );

  const showDependentDetails =
    hasDependents &&
    !userClaimedDependent &&
    (filingStatus !== FILING_STATUS_ANSWER.MARRIED_FILING_JOINTLY ||
      !canSomeoneElseClaimSpouseAsDependent);

  const dependents = showDependentDetails
    ? taxForms
        .filter((taxform) => taxform.formType === ALL_FORM_TYPES.DEPENDENT)
        .map((tf) => tf.formData)
    : [];

  const fields = [
    {
      path: TaxFormFieldIds.DEPENDENT_FIRST_NAME,
      name: 'First name & middle initial',
    },
    {path: TaxFormFieldIds.DEPENDENT_LAST_NAME, name: 'Last name'},
    {path: TaxFormFieldIds.DEPENDENT_RELATION, name: 'Relationship to you'},
    {path: TaxFormFieldIds.DEPENDENT_BIRTHDATE, name: 'Date of birth'},
    {path: TaxFormFieldIds.DEPENDENT_SSN, name: 'Social Security Number'},
    {
      path: TaxFormFieldIds.DEPENDENT_US_CITIZEN,
      name: 'Are they either a US resident or US citizen?',
    },
  ];

  return (
    <SubSectionBox
      name="Dependent details"
      style={style}
      taxprofileSubsection={TaxProfileSubsection.DependentDetails}>
      {!loaded ? (
        <Skeleton width={'100%'} height={40} />
      ) : (
        dependents.map((data: any, index: number) => (
          <DependentCard index={index} data={data} fields={fields} />
        ))
      )}
    </SubSectionBox>
  );
};

export default DependentDetails;
