import TextField from './common/TextField/TextField';
import Text from './common/Text/Text';
import NumberField from './common/NumberField/NumberField';
import RadioField from './common/RadioField/RadioField';
import CheckField from './common/CheckField/CheckField';
import DateField from './common/DateField/DateField';
import {FORM_INPUT_TYPE, FormInputProps} from './Form.types';
import ProfessionField from './Specials/Profession/ProfessionField';
import StateField from './Specials/State/StateField';
import _ from 'lodash';
import ArrayField from './common/ArrayField/ArrayField';

const Form = (props: FormInputProps) => {
  if (!props.inputType) {
    console.log(props);
  }
  switch (props.inputType) {
    case FORM_INPUT_TYPE.String: {
      return <TextField {...props} />;
    }
    case FORM_INPUT_TYPE.Number: {
      return <NumberField {...props} />;
    }
    case FORM_INPUT_TYPE.SingleSelect: {
      return <RadioField {...props} />;
    }
    case FORM_INPUT_TYPE.MultiSelect: {
      return <CheckField {...props} />;
    }
    case FORM_INPUT_TYPE.Date: {
      return <DateField {...props} />;
    }
    case FORM_INPUT_TYPE.Profession: {
      return <ProfessionField {...props} />;
    }
    case FORM_INPUT_TYPE.State: {
      return <StateField {...props} />;
    }
    case FORM_INPUT_TYPE.Array: {
      return <ArrayField {...props} />;
    }
  }
  return (
    <Text fontWeight={Text.FONT_WEIGHTS.ExtraBold} text="not implemented" />
  );
};

const FormWithAttrbutes = Object.assign(Form, {
  Text,
  TextField,
  NumberField,
  RadioField,
  CheckField,
  DateField,
  ArrayField,
  ProfessionField,
  StateField,
});

export {FormWithAttrbutes as Form};
