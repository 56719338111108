import {getFormData} from 'src/CpaCenterUserInfo/CpaCenterReviewAndEsign/ReviewAndEsign.utils';
import {
  createBulkMessageAttUploadURLs,
  generateDocUploadURL,
  getOcrData,
  postDocument,
  submitDocForOCR,
} from 'src/appApi';
import useCurrentUserId from './useCurrentUserId';
import {
  TaxReturnDocumentType,
  TaxReturnType,
} from 'src/store/taxReturns/taxReturns.reducer';
import {pollRequest} from 'src/common/utils';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {BusinessType} from 'src/store/businessDetails/businessDetails.types';

const useUploadDocument = () => {
  const {activeYear} = useActiveYear();
  const {userId} = useCurrentUserId();

  const uploadTaxReturn = async (
    file: any,
    returnId: number,
    returnType: TaxReturnType,
    entityType?: BusinessType,
  ) => {
    let docType = TaxReturnDocumentType.TAX_RETURNS;
    if (returnType === TaxReturnType.BUSINESS) {
      switch (entityType) {
        case BusinessType.C_CORP:
          docType = TaxReturnDocumentType.BUSINESS_RETURN_1120;
          break;
        case BusinessType.S_CORP:
          docType = TaxReturnDocumentType.BUSINESS_RETURN_1120S;
          break;
        case BusinessType.PARTNERSHIP:
          docType = TaxReturnDocumentType.BUSINESS_RETURN_1065;
          break;
      }
    }
    // Generate upload url
    const {data} = await generateDocUploadURL({
      filename: file.name,
      year: activeYear,
    });

    // Upload document
    await postDocument(data.url, getFormData(file, data.fields));

    // Submit document for ocr
    const {data: job_id} = await submitDocForOCR({
      s3_key: data.s3_key,
      doc_type: docType,
    });

    // wait for OCR to complete
    await pollRequest(
      async () => {
        const pollResponse = await getOcrData({
          job_id,
          doc_type: docType,
          end_user_id: userId,
          year: activeYear,
          biz_return_id: returnId,
        });
        return pollResponse;
      },
      (res: any) => {
        return res?.data?.status === 'Done';
      },
      5000,
    );
  };

  const generateBulkMessageAttUploadURLs = async (filenames: string[]) => {
    const response = await createBulkMessageAttUploadURLs(filenames, userId);
    return response.data;
  };

  const uploadMessageAttachmentsToS3 = async (files: any[]) => {
    const urls = await generateBulkMessageAttUploadURLs(
      files.map((file) => file.name),
    );
    const uploadingFilePromises = urls.urls.map((url, index) => {
      return postDocument(url.url, getFormData(files[index], url.fields));
    });
    await Promise.all(uploadingFilePromises);
    const attachments = urls.urls.map((url, index) => {
      return {
        s3_key: url.s3_key,
        filename: files[index].name,
      };
    });
    return attachments;
  };

  return {
    uploadTaxReturn,
    generateBulkMessageAttUploadURLs,
    uploadMessageAttachmentsToS3,
  };
};

export default useUploadDocument;
