import {get} from 'lodash';
import {TAX_PROFILE_FIELDS} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import {ReduxStateType} from '../store';
import {selectActiveYear} from '../globals/globals.selector';

/**
 * @deprecated The method should not be used use selectTaxProfileAnswerForGivenYear
 */
export const selectTaxProfileAnswer: (
  state: ReduxStateType,
  questionId: TAX_PROFILE_FIELDS,
) => any = (state, questionId) => {
  const activeYear = selectActiveYear(state);
  return get(
    state.taxProfile[activeYear]?.find(
      (item) => item.question_id === questionId,
    ),
    'answer',
    undefined,
  );
};

export const selectTaxProfileAnswerForGivenYear: (
  state: ReduxStateType,
  questionId: TAX_PROFILE_FIELDS,
  year: number,
) => any = (state, questionId, year) => {
  return get(
    state.taxProfile[year].find((item) => item.question_id === questionId),
    'answer',
    undefined,
  );
};

export const selectTaxProfile = (state: ReduxStateType) => {
  return state.taxProfile;
};
