const FilledCrossIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM9.07721 8.01654C8.78431 7.72365 8.30944 7.72365 8.01654 8.01654C7.72365 8.30944 7.72365 8.78431 8.01654 9.07721L10.9393 12L8.01654 14.9228C7.72365 15.2157 7.72365 15.6906 8.01654 15.9835C8.30944 16.2763 8.78431 16.2763 9.07721 15.9835L12 13.0607L14.9228 15.9835C15.2157 16.2763 15.6906 16.2763 15.9835 15.9835C16.2763 15.6906 16.2763 15.2157 15.9835 14.9228L13.0607 12L15.9835 9.07721C16.2763 8.78431 16.2763 8.30944 15.9835 8.01654C15.6906 7.72365 15.2157 7.72365 14.9228 8.01654L12 10.9393L9.07721 8.01654Z"
        fill="#FF4B4B"
      />
    </svg>
  );
};

export default FilledCrossIcon;
