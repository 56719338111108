import React from 'react';

const MoreIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <path
        d="M11.9141 6.5C12.8766 6.5 13.6641 5.7125 13.6641 4.75C13.6641 3.7875 12.8766 3 11.9141 3C10.9516 3 10.1641 3.7875 10.1641 4.75C10.1641 5.7125 10.9516 6.5 11.9141 6.5Z"
        fill="white"
      />
      <path
        d="M11.9141 13.8506C12.8766 13.8506 13.6641 13.0631 13.6641 12.1006C13.6641 11.1381 12.8766 10.3506 11.9141 10.3506C10.9516 10.3506 10.1641 11.1381 10.1641 12.1006C10.1641 13.0631 10.9516 13.8506 11.9141 13.8506Z"
        fill="white"
      />
      <path
        d="M11.9141 21C12.8766 21 13.6641 20.2125 13.6641 19.25C13.6641 18.2875 12.8766 17.5 11.9141 17.5C10.9516 17.5 10.1641 18.2875 10.1641 19.25C10.1641 20.2125 10.9516 21 11.9141 21Z"
        fill="white"
      />
    </svg>
  );
};

export default MoreIcon;
