import React, {useCallback, useEffect, useMemo, useState} from 'react';
import InputWrapper, {
  InputWrapperBaseType,
} from '../components/InputWrapper/InputWrapper';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import DownIcon from 'src/icons/DownIcon';
import Option from '../components/Option/Option';
import Text from '../Text/Text';
import _ from 'lodash';

interface CheckFieldOptionType {
  label: any;
  value: any;
}

interface CheckFieldProps extends InputWrapperBaseType {
  options: CheckFieldOptionType[];
  value: (number | string)[];
  onChangeValue: (newValue: any) => void;
}

const CheckField = ({
  title,
  validators,
  options = [],
  value: value,
  onChangeValue,
}: CheckFieldProps) => {
  const [_selectedValues, setSelectedValues] = useState(
    () => new Set([...value]),
  );
  const [optionsPopup, setOptionsPopup] = useState(false);

  const readLabel = useMemo(() => {
    const labels: string[] = [];
    const optionsWithKey = _.keyBy(options, 'value');
    value.forEach((value) => {
      if (optionsWithKey?.[value]) {
        labels.push(optionsWithKey[value].label);
      }
    });
    return labels.join(', ');
  }, [value, options]);

  const onClickOptions: React.MouseEventHandler<HTMLInputElement> =
    useCallback((e) => {
      setOptionsPopup(true);
      e.currentTarget.blur();
    }, []);

  const onClosePopup = useCallback(() => {
    setOptionsPopup(false);
  }, []);

  useEffect(() => {
    if (optionsPopup) {
      setSelectedValues(() => new Set(value));
    }
  }, [optionsPopup, value]);

  const onSelect = useCallback((newValue: any) => {
    setSelectedValues((prev) => {
      const updatedSet = _.cloneDeep(prev);
      updatedSet.add(newValue);
      return updatedSet;
    });
  }, []);

  const onDeselect = useCallback((toRemove: any) => {
    setSelectedValues((prev) => {
      const updatedSet = _.cloneDeep(prev);
      updatedSet.delete(toRemove);
      return updatedSet;
    });
  }, []);

  return (
    <>
      <InputWrapper
        title={title}
        actualValue={value}
        type="text"
        onClick={onClickOptions}
        value={value.length > 0 ? readLabel : 'Not selected'}
        rightIcon={<DownIcon />}
        validators={validators}
      />

      <PopUp
        primaryButtonTitle="Change"
        primaryButtonOnClick={() => {
          onChangeValue([..._selectedValues]);
          onClosePopup();
        }}
        primaryButtonDisabled={_selectedValues.size === 0}
        secondaryButtonTitle="Cancel"
        secondaryButtonOnClick={() => {
          onClosePopup();
        }}
        style={{width: 400}}
        isOpen={optionsPopup}
        onClose={onClosePopup}>
        <Text
          text={title ?? 'Select all applicable'}
          type={Text.TEXT_TYPES.L}
          fontWeight={Text.FONT_WEIGHTS.SemiBold}
        />
        <div style={{maxHeight: 400, overflowY: 'auto'}}>
          {options.map(({label, value}, index) => {
            return (
              <Option
                key={`${value}-${index}`}
                isSingle={false}
                checked={_selectedValues.has(value)}
                label={label}
                value={value}
                onSelect={onSelect}
                onDeselect={onDeselect}
              />
            );
          })}
        </div>
      </PopUp>
    </>
  );
};

export default CheckField;
