import React from 'react';
import {useSelector} from 'react-redux';
import {TaxProfileSubsection} from 'src/constants/constants';
import SubSectionBox from 'src/CpaCenterV2/TaxProfileSections/common/SubSectionBox';
import Skeleton from 'src/icons/Skeleton';
import {selectBusinessDetails} from 'src/store/businessDetails/businessDetails.selector';
import SingleBusinessDetail from './components/SingleBusinessDetail';
import {TAX_PROFILE_FIELDS} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import {selectTaxProfileAnswer} from 'src/store/taxProfile/taxProfile.selector';
import NotApplicableView from 'src/CpaCenterV2/common/NotApplicableView/NotApplicableView';
import {Typography} from '@mui/material';
import {ReduxStateType} from 'src/store/store';

interface BusinessDetailsProps {
  style?: React.CSSProperties;
}

const BusinessDetails = ({style}: BusinessDetailsProps) => {
  const {loaded, businesses} = useSelector(selectBusinessDetails);

  const dontHaveBusiness = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TAX_PROFILE_FIELDS.DONT_HAVE_BUSINESS_DETAILS,
    ),
  );

  return (
    <SubSectionBox
      name={'Business details'}
      style={style}
      taxprofileSubsection={TaxProfileSubsection.BusinessDetails}>
      {!loaded ? (
        <Skeleton width={'100%'} height={40} />
      ) : dontHaveBusiness ? (
        <NotApplicableView>
          <Typography style={{fontSize: '13', fontWeight: 'normal'}}>
            I don’t have freelance/business
          </Typography>
          <Typography style={{fontSize: '13', fontWeight: 'bold'}}>
            Not a freelancer/Business owner
          </Typography>
        </NotApplicableView>
      ) : (
        businesses.map((business, index) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 8,
            }}>
            <SingleBusinessDetail business={business} index={index} />
          </div>
        ))
      )}
    </SubSectionBox>
  );
};

export default BusinessDetails;
