import React, {useEffect, useMemo} from 'react';
import {Typography} from '@mui/material';
import ReturnStatusCard from './components/ReturnStatusCard';
import EfilePaymentDetailsDrawer from './components/EfilePaymentDetailsDrawer';
import {fetchTaxReturns} from 'src/store/taxReturns/taxReturns.actions';
import {useDispatch, useSelector} from 'react-redux';
import useCurrentUserId from '../hooks/useCurrentUserId';
import {selectTaxReturnsReducer} from 'src/store/taxReturns/taxReturns.selector';
import Skeleton from 'src/icons/Skeleton';
import {fetchEfileLogs} from 'src/store/efileLogs/efileLogs.actions';
import {fetchBankDetails} from 'src/store/bankDetails/bankDetails.actions';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {selectBusinessDetails} from 'src/store/businessDetails/businessDetails.selector';
import {
  TaxReturnStatus,
  TaxReturnType,
} from 'src/store/taxReturns/taxReturns.reducer';
import IPPinDetailsDrawer from './components/IPPinDetailsDrawer';

const TaxReturns = () => {
  const {activeYear} = useActiveYear();
  const dispatch = useDispatch();
  const {userId} = useCurrentUserId();

  const {taxReturns, loaded: taxReturnsLoaded} = useSelector(
    selectTaxReturnsReducer,
  );
  const {loaded: businessLoaded} = useSelector(selectBusinessDetails);

  const isAnyReturnSharedWithUser = useMemo(() => {
    return (
      taxReturns?.find((taxReturn) => {
        return [
          TaxReturnStatus.REVIEW_ESIGN,
          TaxReturnStatus.EFILE_STATUS,
        ].includes(taxReturn.status);
      }) != null
    );
  }, [taxReturns]);

  const sortedTaxReturns = useMemo(() => {
    if (taxReturns) {
      const returns = [...taxReturns];
      return returns.sort((a, b) => {
        if (
          a.return_type === TaxReturnType.BUSINESS &&
          b.return_type === TaxReturnType.INDIVIDUAL
        ) {
          return -1;
        } else if (
          a.return_type === TaxReturnType.INDIVIDUAL &&
          b.return_type === TaxReturnType.BUSINESS
        ) {
          return 1;
        }

        return -1;
      });
    }
    return [];
  }, [taxReturns]);

  useEffect(() => {
    dispatch(fetchTaxReturns(userId));
    dispatch(fetchEfileLogs(userId));
    dispatch(fetchBankDetails(userId));
  }, [activeYear]);

  const isLoaded = taxReturnsLoaded && businessLoaded;

  return (
    <>
      <div style={{paddingLeft: 4, overflowY: 'auto'}}>
        <Typography style={{fontSize: 20, fontWeight: 800, marginBlock: 12}}>
          Return review & E-filing
        </Typography>
        {isLoaded ? (
          sortedTaxReturns.map((taxReturn) => (
            <ReturnStatusCard
              key={`${taxReturn.return_id}-${taxReturn.return_type}`}
              taxReturn={taxReturn}
              isAnyReturnSharedWithUser={isAnyReturnSharedWithUser}
            />
          ))
        ) : (
          <>
            <Skeleton width={'100%'} height={100} />
            <Skeleton width={'100%'} height={100} />
            <Skeleton width={'100%'} height={100} />
          </>
        )}
      </div>
      <EfilePaymentDetailsDrawer />
      <IPPinDetailsDrawer />
    </>
  );
};

export default TaxReturns;
