import {getStateReturnsInfo} from 'src/appApi';
import {SET_TAX_PROFILE_STATES_INFO} from '../actionNames';
import {setAppState} from '../app/app.actions';
import {AppReducerStates, NotificationType} from '../app/app.reducer';
import {selectActiveYear} from '../globals/globals.selector';
import {getStateType} from '../store';

const setTaxProfileStatesInfo = (payload) => {
  return {
    type: SET_TAX_PROFILE_STATES_INFO,
    payload,
  };
};

export const fetchTaxProfileStatesInfo = (userId: number) => {
  return async (dispatch: any, getState: getStateType) => {
    const activeYear = selectActiveYear(getState());
    try {
      const {data} = await getStateReturnsInfo({
        fly_user_id: userId,
        year: activeYear,
      });
      const payload = {
        statesLivedInfo: data.states_info,
        businessStateReturnInfo: data.business_tax_returns_info,
        isDrivingLicenseRequired: data.is_driving_license_required,
      };
      dispatch(setTaxProfileStatesInfo(payload));
    } catch (e) {
      dispatch(
        setAppState(AppReducerStates.notifcationStatus, {
          isVisible: true,
          message: `Failed to tax-profile states info (${e})`,
          type: NotificationType.error,
        }),
      );
    }
  };
};
