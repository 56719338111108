import _ from 'lodash';
import {FormConfigProps, FormInputProps} from '../../Form.types';
import Text from '../Text/Text';
import {useFormData} from 'src/CpaCenterV2/hooks/useFormData';
import {themmeColor} from 'src/constants/constants';

type ChildProps = FormConfigProps[];

export interface ArrayProps {
  title: string;
  childTitle?: string;
  childProps: ChildProps;
  value: Array<any>;
  onChangeValue: (newValue: Array<any>) => void;
}

const TempForm = ({
  props,
  value,
  onChangeValue,
}: {
  props: FormConfigProps[];
  value: any;
  onChangeValue: (newValue: any) => void;
}) => {
  const {Form: DynamicForm} = require('src/DesignSystem/Form/Form');

  const {fields} = useFormData({
    config: props,
    data: value,
    setData: onChangeValue,
  });

  return fields.map((field) => {
    return <DynamicForm {...field} />;
  });
};

const ArrayField = ({
  title,
  childTitle,
  childProps,
  value,
  onChangeValue,
}: ArrayProps) => {
  const onChangeArrayItem = (item: any, index: number) => {
    const updatedValue = [...value];
    updatedValue[index] = _.cloneDeep(item);
    onChangeValue(updatedValue);
  };

  return (
    <div>
      <Text
        type={Text.TEXT_TYPES.L}
        fontWeight={Text.FONT_WEIGHTS.ExtraBold}
        text={title}
      />
      {value.map((arrayObject, arrayObjectIndex) => {
        const updateObject = (getState: any) => {
          // pass old state to get new updated State
          const newState = getState(_.cloneDeep(arrayObject));
          onChangeArrayItem(newState, arrayObjectIndex);
        };
        return (
          <div
            style={{
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: themmeColor.silver,
              marginBottom: 8,
              borderRadius: 12,
              padding: 16,
            }}>
            <Text
              type={Text.TEXT_TYPES.BASE}
              fontWeight={Text.FONT_WEIGHTS.SemiBold}
              text={`${childTitle} ${arrayObjectIndex + 1}`}
            />
            <TempForm
              props={childProps}
              value={value[arrayObjectIndex]}
              onChangeValue={updateObject}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ArrayField;
