import React from 'react';
import {FormControl, MenuItem, Select, SelectChangeEvent, Typography} from '@mui/material';
import {VALID_CPA_EMAILS} from 'src/constants/constants';
import _ from 'lodash';

type Props = {
  value: string | string[] | null,
  onChange: (value: string) => void;
  placeholder?: string;
  multiSelect?: boolean;
};

const SelectCPACell = ({value, onChange, placeholder = 'Select', multiSelect = false}: Props) => {
  const CPAs = Object.values(VALID_CPA_EMAILS).filter(
    (v) => v != VALID_CPA_EMAILS.Unassigned,
  );

  const preventPropagation = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleChange = (e: SelectChangeEvent) => {
    preventPropagation(e);
    onChange(e.target.value);
  };

  const renderSelectedValue = (value) => {
    if (_.isEmpty(value)) return placeholder;

    if (multiSelect) {
      return (
        <div style={{display: 'flex', flexDirection: 'column', padding: 8}}>
          {value.map((cpa: VALID_CPA_EMAILS) => (
            <Typography>{cpa},</Typography>
          ))}
        </div>
      );
    }
    return value;
  };

  return (
    <FormControl variant='standard'>
      <Select
        variant={'standard'}
        renderValue={renderSelectedValue}
        value={value == null ? '' : value}
        displayEmpty={true}
        onClick={preventPropagation}
        onChange={handleChange}
        multiple={multiSelect}
      >
        {CPAs.map((cpa) => (
          <MenuItem value={cpa}>
            {cpa}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectCPACell;
