import React, {useState} from 'react';
import {Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {postCCHStatus, updateReturnStatus} from 'src/appApi';
import DSButton from 'src/DesignSystem/Button/Button';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import Skeleton from 'src/icons/Skeleton';
import {
  CreateQueryDrawerTab,
  NotificationType,
} from 'src/store/app/app.reducer';
import {fetchCCHStatus} from 'src/store/cchStatus/cchStatus.actions';
import {selectCCHStatus} from 'src/store/cchStatus/cchStatus.selector';
import ActionRequired from '../common/ActionRequired/ActionRequired';
import useCurrentUserId from '../hooks/useCurrentUserId';
import CCHSections from './components/CCHSections';
import SendToCCHErrorOverlay from './components/SendToCCHErrorOverlay';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import useCpaCenterV2Navigation from '../hooks/useCpaCenterV2Navigation';
import {selectTaxReturnsReducer} from 'src/store/taxReturns/taxReturns.selector';
import {
  TaxReturnStatus,
  TaxReturnType,
} from 'src/store/taxReturns/taxReturns.reducer';
import useLayout from '../hooks/useLayout';
import {TAX_FILING_STATUS} from 'src/constants/constants';
import {fetchTaxReturns} from 'src/store/taxReturns/taxReturns.actions';

const SentToCCH = () => {
  const {activeYear} = useActiveYear();
  const {userId} = useCurrentUserId();
  const [selectedSections, setSelectedSections] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorOverlay, setErrorOverlay] = useState<{
    error: string;
    isVisible: boolean;
  }>({error: '', isVisible: false});
  const {notify} = useNotify();
  const {loaded: cchStatusLoaded, sectionWiseSubsection} =
    useSelector(selectCCHStatus);
  const {taxReturns, loaded: taxReturnsLoaded} = useSelector(
    selectTaxReturnsReducer,
  );

  const {navigateToTaxReturnDetailedView} = useCpaCenterV2Navigation();
  const {DOCUMENT_BOTTOM_BAR_HEIGHT, spaceLeftBelowTaxFilingSteps} =
    useLayout();

  const returnId = taxReturns.find(
    (taxReturn) => taxReturn.return_type === TaxReturnType.INDIVIDUAL,
  )?.return_id;
  const returnType = taxReturns.find(
    (taxReturn) => taxReturn.return_type === TaxReturnType.INDIVIDUAL,
  )?.return_type;

  const dispatch = useDispatch();

  const isLoaded = taxReturnsLoaded && cchStatusLoaded;

  const sendToCCH = async () => {
    try {
      setIsLoading(true);
      await postCCHStatus({
        fly_user_id: userId,
        year: activeYear,
        config: selectedSections,
      });
    } catch (e) {
      if (e?.response?.status === 400) {
        setErrorOverlay({
          error: e?.response?.data?.error_msg ?? '',
          isVisible: true,
        });
      }
      notify(`Failed to send to cch ${e}`, NotificationType.error);
    } finally {
      dispatch(fetchCCHStatus(userId));
      setIsLoading(false);
    }
  };

  const onCompleteCCHStep = async () => {
    if (returnId && returnType) {
      await updateReturnStatus(
        {tax_filing_id: returnId},
        {
          return_type: returnType,
          cpa_facing_ret_status: TAX_FILING_STATUS.DETAILS_SENT_TO_CCH,
        },
      );
      await dispatch(fetchTaxReturns(userId));
      navigateToTaxReturnDetailedView(
        returnId,
        returnType,
        TaxReturnStatus.ADD_RETURN,
      );
    }
  };

  return (
    <>
      <div
        style={{
          height: spaceLeftBelowTaxFilingSteps - 80,
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            height:
              spaceLeftBelowTaxFilingSteps - DOCUMENT_BOTTOM_BAR_HEIGHT - 80,
          }}>
          <div
            style={{
              marginLeft: 8,
              width: '60%',
              marginTop: 12,
            }}>
            {!isLoaded ? (
              <>
                <Skeleton
                  style={{marginBottom: 24}}
                  height={40}
                  width={'100%'}
                />
                <Skeleton
                  style={{marginBottom: 12}}
                  height={80}
                  width={'100%'}
                />
                <Skeleton
                  style={{marginBottom: 12}}
                  height={80}
                  width={'100%'}
                />
                <Skeleton
                  style={{marginBottom: 12}}
                  height={80}
                  width={'100%'}
                />
              </>
            ) : (
              <>
                <div
                  style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Typography style={{fontWeight: 800, fontSize: 18}}>
                    Send to CCH
                  </Typography>
                  <div style={{display: 'flex'}}>
                    <DSButton
                      type="secondary"
                      disabled={isLoading}
                      onClick={() =>
                        setSelectedSections({...sectionWiseSubsection})
                      }
                      text="Select all"
                      style={{marginRight: 8}}
                    />
                    <DSButton
                      type="secondary"
                      disabled={isLoading}
                      onClick={() => setSelectedSections({})}
                      text="Clear all"
                      style={{marginRight: 8}}
                    />
                    <DSButton
                      type="primary"
                      disabled={isLoading}
                      onClick={sendToCCH}
                      text="Send"
                    />
                  </div>
                </div>
                <div
                  style={{
                    overflowY: 'auto',
                    height: 'calc(100% - 50px)',
                    marginTop: 20,
                  }}>
                  <CCHSections
                    selectedSections={selectedSections}
                    setSelectedSections={setSelectedSections}
                    isLoading={isLoading}
                  />
                </div>
              </>
            )}
          </div>
          <div style={{overflowY: 'auto', width: '35%'}}>
            <ActionRequired
              types={[
                CreateQueryDrawerTab.DOCUMENT,
                CreateQueryDrawerTab.TAX_PROFILE,
                CreateQueryDrawerTab.HIGH_VALUE_DEDUCTIONS,
                CreateQueryDrawerTab.DISTRIBUTE_DEDUCTIONS,
              ]}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            height: DOCUMENT_BOTTOM_BAR_HEIGHT,
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '30px 40px',
            borderWidth: 1,
            background: '#F5F6F8',
            borderColor: '#E1E1E1',
          }}>
          <Typography style={{fontSize: 14, fontWeight: 400, lineHeight: 24}}>
            Send all the data to CCH once you have clarified all the queries
            with the user
          </Typography>
          <DSButton
            type="primary"
            text="Next"
            onClick={onCompleteCCHStep}
            disabled={false}
            style={{paddingInline: 80, paddingBlock: 10}}
          />
        </div>
      </div>
      <SendToCCHErrorOverlay
        isOpen={errorOverlay.isVisible}
        error={errorOverlay.error}
        onClose={() => setErrorOverlay({error: '', isVisible: false})}
      />
    </>
  );
};

export default SentToCCH;
