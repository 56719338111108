import React from 'react';
import {TaxProfileSubsection} from 'src/constants/constants';
import SubSectionBox from '../../common/SubSectionBox';
import {useSelector} from 'react-redux';
import {selectTaxProfileAnswer} from 'src/store/taxProfile/taxProfile.selector';
import {TAX_PROFILE_FIELDS} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import SimpleKeyValueField from '../../common/SimpleKeyValueField';
import CommonDeductionTable from './CommonDeductionTable';
import {
  TaxProfileSummaryVariant,
  useTaxProfileSummaryFilter,
} from '../../common/TaxProfileSummaryFilter/useTaxProfileSummaryFilter';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

const SalaryAndWagesPaid = () => {
  const {activeYear} = useActiveYear();
  const paidWages = useSelector((state) =>
    selectTaxProfileAnswer(state, TAX_PROFILE_FIELDS.PAID_WAGES_EMP_BENEFITS),
  );
  const {summaryVariant} = useTaxProfileSummaryFilter();

  return (
    <SubSectionBox
      name="Salary and wages paid"
      taxprofileSubsection={TaxProfileSubsection.SalaryAndWagesPaid}>
      <div
        style={
          summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
            ? {}
            : {display: 'flex', flex: 1, justifyContent: 'space-between'}
        }>
        <div
          style={
            summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
              ? {}
              : {flex: 0.48}
          }>
          <SimpleKeyValueField
            name={`Did you have employee(s) who you paid wages and employee benefits in ${activeYear}?`}
            value={paidWages}
          />
        </div>
        <div
          style={
            summaryVariant === TaxProfileSummaryVariant.USER_QUERIES_VARAINT
              ? {marginTop: 20}
              : {flex: 0.48}
          }>
          <CommonDeductionTable
            taxProfileSubsection={TaxProfileSubsection.SalaryAndWagesPaid}
          />
        </div>
      </div>
    </SubSectionBox>
  );
};

export default SalaryAndWagesPaid;
