import {useNotify} from 'react-admin';
import {useSelector} from 'react-redux';
import {ALL_FORM_TYPES, USAStatesAbbreviations} from 'src/constants/constants';
import {
  FIELD_ID,
  TAX_PROFILE_FIELDS,
} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import {NotificationType} from 'src/store/app/app.reducer';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.constants';
import {TaxForm} from 'src/store/taxForms/taxForms.reducer';
import {selectTaxFormsReducer} from 'src/store/taxForms/taxForms.selector';
import {selectTaxProfileAnswer} from 'src/store/taxProfile/taxProfile.selector';

const useStateInfo = () => {
  const {taxForms} = useSelector(selectTaxFormsReducer);
  const stateLivedTaxForms = taxForms.filter(
    (tf) => tf.formType === ALL_FORM_TYPES.STATES_LIVED,
  );
  const homeAddress = useSelector((state) =>
    selectTaxProfileAnswer(state, TAX_PROFILE_FIELDS.HOME_ADDRESS),
  );
  const {notify} = useNotify();

  const getFieldsFromHomeAddress = () => {
    return {
      [FIELD_ID.STREET_NUMBER]: homeAddress?.[FIELD_ID.STREET_NUMBER],
      [FIELD_ID.APARTMENT_NUMBER]: homeAddress?.[FIELD_ID.APARTMENT_NUMBER],
      [FIELD_ID.STATE]: homeAddress?.[FIELD_ID.STATE],
      [FIELD_ID.CITY]: homeAddress?.[FIELD_ID.CITY],
      [FIELD_ID.ZIP_CODE]: homeAddress?.[FIELD_ID.ZIP_CODE],
      [FIELD_ID.SCHOOL_DISTRICT_CODE]:
        homeAddress?.[FIELD_ID.SCHOOL_DISTRICT_CODE],
      [FIELD_ID.LIVED_FOR]: homeAddress?.[FIELD_ID.LIVED_FOR],
      current_address: true,
    };
  };

  const getFullStateName = (smallName: string) => {
    const fullStateInfo = USAStatesAbbreviations.find(
      (item) => item.id === smallName,
    );
    if (fullStateInfo) {
      return fullStateInfo.name;
    }
    notify(`Abbrevation not found for ${smallName}`, NotificationType.warning);
    return smallName;
  };

  const getFieldsForStateLivedForm = ({formData}: TaxForm) => {
    return {
      [FIELD_ID.STREET_NUMBER]: formData[TaxFormFieldIds.NUMBER_AND_STREET],
      [FIELD_ID.APARTMENT_NUMBER]: formData[TaxFormFieldIds.APARTMENT_NUMBER],
      [FIELD_ID.CITY]: formData[TaxFormFieldIds.CITY],
      [FIELD_ID.STATE]: getFullStateName(formData[TaxFormFieldIds.STATE]),
      [FIELD_ID.ZIP_CODE]: formData[TaxFormFieldIds.ZIP_CODE],
      [FIELD_ID.LIVED_FOR]: formData[TaxFormFieldIds.LIVED_FOR],
      [FIELD_ID.SCHOOL_DISTRICT_CODE]:
        formData[TaxFormFieldIds.SCHOOL_DISTRICT_CODE],
      current_address: false,
    };
  };

  let statesInfo = [getFieldsFromHomeAddress()];
  //   @ts-ignore
  statesInfo = [
    ...statesInfo,
    ...stateLivedTaxForms.map(getFieldsForStateLivedForm),
  ];

  const fields = [
    {name: 'House number and street', path: FIELD_ID.STREET_NUMBER},
    {
      name: 'Apartment/Unit number (if applicable)',
      path: FIELD_ID.APARTMENT_NUMBER,
    },
    {name: 'City', path: FIELD_ID.CITY},
    {name: 'State', path: FIELD_ID.STATE},
    {name: 'Zip code', path: FIELD_ID.ZIP_CODE},
    {name: 'School district code', path: FIELD_ID.SCHOOL_DISTRICT_CODE},
    {name: 'Lived for', path: FIELD_ID.LIVED_FOR},
  ];
  return {
    statesInfo,
    fields,
  };
};

export default useStateInfo;
