import {Typography} from '@mui/material';
import React, {useState} from 'react';
import DSButton from 'src/DesignSystem/Button/Button';
import ManualSignNowIdPopup from './components/ManualSignNowIdPopup';
import Threads from './components/Threads';
import ReviewReturnPlaceholderPopup from './components/ReviewReturnPlaceholderPopup';
import {useSelector} from 'react-redux';
import {selectThreadsForCurrReturn} from 'src/store/threads/threads.selector';
import {ThreadStatus} from 'src/store/threads/threads.reducer';
import useLayout from 'src/CpaCenterV2/hooks/useLayout';
import AssignUserQueriesPopup from './components/AssignUserQueriesPopup';
import {ReduxStateType} from 'src/store/store';
import useCurrentTaxReturnId from 'src/CpaCenterV2/hooks/useCurrentTaxReturnId';
import {selectTaxReturnsReducer} from 'src/store/taxReturns/taxReturns.selector';
import {TaxReturnStatus} from 'src/store/taxReturns/taxReturns.reducer';

const CpaReview = ({url}: {url: string | null}) => {
  const {currentReturn} = useCurrentTaxReturnId();
  const {threads} = useSelector((state: ReduxStateType) =>
    selectThreadsForCurrReturn(state, currentReturn),
  );
  const {taxReturns} = useSelector(selectTaxReturnsReducer);

  const pendintNotesCount = threads.filter(
    (thread) => thread.status === ThreadStatus.OPEN,
  ).length;

  const [isReviewReturnPopup, setIsReviewReturnPopup] = useState(false);
  const [isManualSingNowPopup, setIsManualSignNowPopup] = useState(false);
  const [isAssignQueriesPopup, setIsAssignQueriesPopup] = useState(false);
  const {spaceLeftBelowTaxReturnSection, TAX_RETURN_BOTTOM_BAR} = useLayout();

  const onCloseReviewReturnPopup = () => {
    setIsReviewReturnPopup(false);
  };

  const openReviewReturnPopup = () => {
    setIsReviewReturnPopup(true);
  };

  const openAssignQueriesToReturn = () => {
    const returnSentToUserTaxReturn = taxReturns?.find((taxReturn) => {
      return [
        TaxReturnStatus.REVIEW_ESIGN,
        TaxReturnStatus.EFILE_STATUS,
      ].includes(taxReturn.status);
    });

    if (returnSentToUserTaxReturn) {
      openReviewReturnPopup();
    } else {
      setIsAssignQueriesPopup(true);
    }
  };

  const openManualSignNowPopup = () => {
    setIsManualSignNowPopup(true);
    onCloseReviewReturnPopup();
  };
  const closeManualSingnowPopup = () => {
    setIsManualSignNowPopup(false);
  };
  if (!url) {
    return null;
  }

  return (
    <div>
      <div style={{display: 'flex'}}>
        <iframe
          title="Review-esign-return"
          src={url}
          style={{
            width: '70%',
            height: spaceLeftBelowTaxReturnSection - TAX_RETURN_BOTTOM_BAR,
          }}
        />
        <div
          style={{
            height: spaceLeftBelowTaxReturnSection - TAX_RETURN_BOTTOM_BAR,
            width: '30%',
          }}>
          <Threads />
        </div>
      </div>
      <div
        style={{
          height: TAX_RETURN_BOTTOM_BAR,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Typography style={{marginLeft: 4, fontWeight: 600}}>
          You can send the uploaded return to the user since there are no
          pending notes!
        </Typography>
        <DSButton
          type="primary"
          text="Prepare for Review & e-sign"
          style={{paddingInline: '7%', paddingBlock: 12}}
          disabled={pendintNotesCount > 0}
          onClick={openAssignQueriesToReturn}
        />
      </div>
      <ReviewReturnPlaceholderPopup
        key={`review-return-placeholder-popup-${isReviewReturnPopup}`}
        url={url}
        isOpen={isReviewReturnPopup}
        onClose={onCloseReviewReturnPopup}
        onSendManually={openManualSignNowPopup}
      />
      <ManualSignNowIdPopup
        key={`manual-signnow-id-popup-${isManualSingNowPopup}`}
        isOpen={isManualSingNowPopup}
        onClose={closeManualSingnowPopup}
        url={url}
      />
      <AssignUserQueriesPopup
        key={`assign-queries-popup-${isAssignQueriesPopup}`}
        isOpen={isAssignQueriesPopup}
        onClose={() => setIsAssignQueriesPopup(false)}
        onConfirm={openReviewReturnPopup}
      />
    </div>
  );
};

export default CpaReview;
