import {ComponentProps} from 'react';
import TextField from './common/TextField/TextField';
import NumberField from './common/NumberField/NumberField';
import RadioField from './common/RadioField/RadioField';
import CheckField from './common/CheckField/CheckField';
import DateField from './common/DateField/DateField';
import ProfessionField from './Specials/Profession/ProfessionField';
import StateField from './Specials/State/StateField';
import ArrayField from './common/ArrayField/ArrayField';

export enum FORM_INPUT_TYPE {
  String = 'String',
  Number = 'Number',
  SingleSelect = 'SingleSelect',
  MultiSelect = 'MultiSelect',
  Date = 'Date',
  Profession = 'Profession',
  State = 'State',
  Array = 'Array',
}

interface BaseInputProps {
  inputType: FORM_INPUT_TYPE;
}

interface StringProps
  extends BaseInputProps,
    ComponentProps<typeof TextField> {
  inputType: FORM_INPUT_TYPE.String;
}

interface NumberProps
  extends BaseInputProps,
    ComponentProps<typeof NumberField> {
  inputType: FORM_INPUT_TYPE.Number;
}

interface SingleSelectProps
  extends BaseInputProps,
    ComponentProps<typeof RadioField> {
  inputType: FORM_INPUT_TYPE.SingleSelect;
}

interface MultiSelectProps
  extends BaseInputProps,
    ComponentProps<typeof CheckField> {
  inputType: FORM_INPUT_TYPE.MultiSelect;
}

interface DateProps extends BaseInputProps, ComponentProps<typeof DateField> {
  inputType: FORM_INPUT_TYPE.Date;
}

type ProfessionProps = BaseInputProps &
  ComponentProps<typeof ProfessionField> & {
    inputType: FORM_INPUT_TYPE.Profession;
  };

type StateProps = BaseInputProps &
  ComponentProps<typeof StateField> & {
    inputType: FORM_INPUT_TYPE.State;
  };

type ArrayProps = ComponentProps<typeof ArrayField> & {
  inputType: FORM_INPUT_TYPE.Array;
};

export type FormConfigProps = {path: string} & (
  | Pick<StringProps, 'inputType' | 'title' | 'validators'>
  | Pick<NumberProps, 'inputType' | 'title' | 'validators'>
  | Pick<SingleSelectProps, 'inputType' | 'title' | 'validators' | 'options'>
  | Pick<MultiSelectProps, 'inputType' | 'title' | 'validators' | 'options'>
  | Pick<DateProps, 'inputType' | 'title' | 'validators'>
  | Pick<ProfessionProps, 'inputType' | 'title' | 'validators' | 'isSingle'>
  | Pick<
      StateProps,
      'inputType' | 'title' | 'validators' | 'isSingle' | 'useAbbrevation'
    >
  | Pick<ArrayProps, 'inputType' | 'title' | 'childProps' | 'childTitle'>
);

export type FormInputProps =
  | StringProps
  | NumberProps
  | SingleSelectProps
  | MultiSelectProps
  | DateProps
  | ProfessionProps
  | StateProps
  | ArrayProps;
