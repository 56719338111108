import {Typography} from '@mui/material';
import React, {useState} from 'react';
import {FileUploader} from 'react-drag-drop-files';
import {useDispatch} from 'react-redux';
import {themmeColor} from 'src/constants/constants';
import useCpaCenterV2Navigation from 'src/CpaCenterV2/hooks/useCpaCenterV2Navigation';
import useCurrentTaxReturnId from 'src/CpaCenterV2/hooks/useCurrentTaxReturnId';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import useUploadDocument from 'src/CpaCenterV2/hooks/useUploadDocument';
import Box from 'src/DesignSystem/Box/Box';
import DSButton from 'src/DesignSystem/Button/Button';
import FullScreenLoading from 'src/DesignSystem/FullScreenLoading/FullScreenLoading';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import UploadCloudIcon from 'src/icons/UploadCloudIcon';
import {NotificationType} from 'src/store/app/app.reducer';
import {fetchTaxReturns} from 'src/store/taxReturns/taxReturns.actions';
import {TaxReturnStatus} from 'src/store/taxReturns/taxReturns.reducer';

const fileTypes = ['pdf'];

interface UploadReturnProps {
  onDone?: () => void;
}

const UploadReturn = ({onDone = () => {}}: UploadReturnProps) => {
  const {userId} = useCurrentUserId();
  const {returnId, currentReturn, currentReturnBizEntityType, isLoaded} =
    useCurrentTaxReturnId();
  const dispatch = useDispatch();
  const [isDropping, setIsDropping] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [isUploading, setIsUploading] = useState(false);
  const {notify} = useNotify();
  const selectFile = (file: any) => {
    setFile(file);
  };

  const {uploadTaxReturn} = useUploadDocument();
  const {navigateToTaxReturnDetailedView} = useCpaCenterV2Navigation();

  const handleUploadFile = async () => {
    try {
      setIsUploading(true);
      await uploadTaxReturn(
        file,
        returnId,
        currentReturn.return_type,
        currentReturnBizEntityType,
      );
      selectFile(null);
      await dispatch(fetchTaxReturns(userId));
      navigateToTaxReturnDetailedView(
        returnId,
        currentReturn?.return_type,
        TaxReturnStatus.REVIEW_SUMMARY,
      );
      onDone();
      notify('Tax return uploaded successfully', NotificationType.success);
    } catch (e) {
      notify('Failed to upload tax return', NotificationType.error);
    } finally {
      setIsUploading(false);
    }
  };

  const notifyFileTypeError = (err: string) => {
    notify(
      `${err}, Supported type ${fileTypes.join(', ')}`,
      NotificationType.error,
    );
  };

  return (
    <>
      <FullScreenLoading open={isUploading || !isLoaded} />
      {file !== null && (
        <Box
          style={{
            height: '100%',
            backgroundColor: themmeColor.cpaCenterV2Bg,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Typography
            style={{fontSize: 20, fontWeight: 800, marginBottom: 20}}>
            "<span style={{color: themmeColor.errorRed}}>{file.name}</span>" is
            ready to upload
          </Typography>
          <div style={{display: 'flex'}}>
            <DSButton
              type="secondary"
              onClick={() => selectFile(null)}
              text={'Clear'}
              style={{marginRight: 20}}
              disabled={isUploading}
            />
            <DSButton
              type={'primary'}
              onClick={handleUploadFile}
              text={'Upload return'}
              disabled={isUploading}
            />
          </div>
        </Box>
      )}
      {file === null && (
        <FileUploader
          multiple={false}
          dropMessageStyle={{backgroundColor: '#00000000'}}
          hoverTitle={<h2 color={themmeColor.black}>Drop the file</h2>}
          onDraggingStateChange={setIsDropping}
          types={fileTypes}
          fileOrFiles={file}
          onTypeError={notifyFileTypeError}
          handleChange={selectFile}>
          <Box
            style={{
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: themmeColor.cpaCenterV2Bg,
              height: '100%',
              opacity: isDropping ? 0.1 : 1,
            }}>
            <div style={{display: 'flex'}}>
              <div style={{marginRight: 12}}>
                <UploadCloudIcon />
              </div>
              <Typography style={{fontWeight: 600}}>
                Drag & drop the final tax return
              </Typography>
            </div>
            <Typography style={{marginBottom: 12}}>OR</Typography>
            <DSButton
              type="primary"
              onClick={() => {}}
              text="Select file"
              style={{width: '30%'}}
            />
          </Box>
        </FileUploader>
      )}
    </>
  );
};

export default UploadReturn;
