import {getCCHSyncStatus} from 'src/appApi';
import {SET_CCH_STATUS} from '../actionNames';
import {setAppState} from '../app/app.actions';
import {AppReducerStates, NotificationType} from '../app/app.reducer';
import {CCHStatusInfo, CCHStatusStateType} from './cchStatus.reducer';
import {selectActiveYear} from '../globals/globals.selector';
import {getStateType} from '../store';

const setCCHStatus = (
  payload: Omit<CCHStatusStateType, 'loaded' | 'status'>,
) => {
  return {
    type: SET_CCH_STATUS,
    payload,
  };
};

export const fetchCCHStatus = (user_id: number) => {
  return async (dispatch: any, getState: getStateType) => {
    const activeYear = selectActiveYear(getState());
    try {
      const {
        data: {status_info: data},
      } = await getCCHSyncStatus(user_id, activeYear);
      const sections = Object.keys(data);
      const sectionWiseSubsection = {};
      const status_info: CCHStatusInfo = {};
      sections.forEach((section) => {
        const subsections = Object.keys(data[section]);
        sectionWiseSubsection[section] = subsections;
        status_info[section] = {};
        subsections.forEach((subsection) => {
          const {status, last_synced_at, error_msg} =
            data[section][subsection];
          status_info[section][subsection] = {
            status,
            last_synced_at: last_synced_at ? new Date(last_synced_at) : null,
            error_msg,
          };
        });
      });
      dispatch(setCCHStatus({status_info, sections, sectionWiseSubsection}));
    } catch (e) {
      dispatch(
        setAppState(AppReducerStates.notifcationStatus, {
          isVisible: true,
          message: `Failed to fetch cch status (${e})`,
          type: NotificationType.error,
        }),
      );
    }
  };
};
