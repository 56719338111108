import React from 'react';
import {Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import MessageTextBox from 'src/CpaCenterV2/common/MessageTextBox/MessageTextBox';
import useCurrentQueryId from 'src/CpaCenterV2/hooks/useCurrentQueryId';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import useQueryAction from 'src/CpaCenterV2/hooks/useQueryAction';
import DSButton from 'src/DesignSystem/Button/Button';
import {fetchQueries} from 'src/store/queries/queries.actions';
import {
  DerivedMessageType,
  ReduxQueryType,
} from 'src/store/queries/queries.reducer';
import {
  selectQueriesReducer,
  selectQuery,
} from 'src/store/queries/queries.selector';
import {isQueryResolvedByCpaOrTerminallyResolved} from 'src/store/queries/queries.utils';
import {useMessageList} from './useMessageList';
import {COMMON_COMPONENTS_ID} from 'src/constants/constants';
import useUploadDocument from 'src/CpaCenterV2/hooks/useUploadDocument';

const MessageListStickyFooter = () => {
  const {queryId} = useCurrentQueryId();
  const query = useSelector((state: ReduxQueryType) =>
    //   @ts-ignore
    selectQuery(state, queryId),
  );
  const {loaded} = useSelector(selectQueriesReducer);
  const {
    isLoading,
    message,
    setMessage,
    draftEditMode,
    setIsLoading,
    hasClickedOnReopen,
    setHasClickedOnReopen,
    files,
    setFiles,
  } = useMessageList();
  const dispatch = useDispatch();
  const {userId} = useCurrentUserId();

  const {createMessage, patchMessage} = useQueryAction();

  const {uploadMessageAttachmentsToS3} = useUploadDocument();

  if (!query) {
    return null;
  }

  const isResolved =
    loaded &&
    isQueryResolvedByCpaOrTerminallyResolved(query) &&
    !hasClickedOnReopen;

  const onClickSend = async (isDraft = false) => {
    try {
      setIsLoading(true);
      let attachments = undefined;
      if (files?.length > 0)
        attachments = await uploadMessageAttachmentsToS3(files);
      await createMessage({
        message,
        attachments,
        // @ts-ignore
        queryId,
        is_draft: isDraft,
      });
      await dispatch(fetchQueries({userId}));
      setMessage('');
      setFiles([]);
    } finally {
      setIsLoading(false);
    }
  };

  const onDoneEditing = async () => {
    try {
      setIsLoading(true);
      await patchMessage({messageId: draftEditMode.draftMessageId, message});
      setMessage('');
      await dispatch(fetchQueries({userId}));
    } finally {
      setIsLoading(false);
    }
  };

  const onReopenQuery = async () => {
    setHasClickedOnReopen(true);
  };
  if (
    query.messages.every(
      (message) =>
        message.derivedMessageType === DerivedMessageType.DRAFT_MESSAGE,
    ) &&
    !draftEditMode.isDraftEditMode
  ) {
    return null;
  }

  return (
    <div
      id={COMMON_COMPONENTS_ID.CPA_CENTER_V2_MESSAGE_LIST_STICKY_FOOTER}
      style={{paddingInline: 20, paddingBottom: 8}}>
      {isResolved ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Typography style={{fontWeight: 600}}>
            Want to re-open the query?
          </Typography>
          <DSButton
            text="Re-open"
            type="primary"
            onClick={onReopenQuery}
            disabled={isLoading || !loaded || !queryId || !query}
          />
        </div>
      ) : (
        <MessageTextBox
          message={message}
          setMessage={setMessage}
          files={files}
          setFiles={setFiles}
          placeholder={'Write your response...'}
          height={200}
          inputMode={
            draftEditMode.isDraftEditMode
              ? MessageTextBox.MessageTextBoxInputMode.EDIT_DRAFT
              : MessageTextBox.MessageTextBoxInputMode.SEND_MESSAGE
          }
          isLoading={isLoading}
          onDoneEditing={onDoneEditing}
          onSend={() => onClickSend(false)}
          onDraft={() => onClickSend(true)}
          sendDisabled={!queryId || !query || !loaded}
          draftDisabled={!queryId || !query || !loaded}
        />
      )}
    </div>
  );
};

export default MessageListStickyFooter;
