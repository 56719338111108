import React, {useState} from 'react';
import {Typography} from '@mui/material';
import {themmeColor} from 'src/constants/constants';
import Box from 'src/DesignSystem/Box/Box';
import DSButton from 'src/DesignSystem/Button/Button';
import {
  EFILE_STATUS_INFO,
  TaxReturn,
  TaxReturnStatus,
  TaxReturnType,
} from 'src/store/taxReturns/taxReturns.reducer';
import useTaxReturnStep from 'src/CpaCenterV2/hooks/useTaxReturnStep';
import Steps from 'src/DesignSystem/Steps/Steps';
import useCpaCenterV2Navigation from 'src/CpaCenterV2/hooks/useCpaCenterV2Navigation';
import ActivityTable from '../../common/ActivityTable/ActivityTable';
import TickIcon from 'src/icons/TickIcon';
import RightChveron from 'src/icons/RightChveron';
import {useDispatch, useSelector} from 'react-redux';
import {setAppState} from 'src/store/app/app.actions';
import {AppReducerStates} from 'src/store/app/app.reducer';
import {selectThreadsForCurrReturn} from 'src/store/threads/threads.selector';
import {ThreadStatus} from 'src/store/threads/threads.reducer';
import {selectQueriesForCurrReturn} from 'src/store/queries/queries.selector';
import {isQueryResolvedByCpaOrTerminallyResolved} from 'src/store/queries/queries.utils';
import {QuerySubType} from 'src/store/queries/queries.reducer';
import {selectEfileLogsForCurrReturn} from 'src/store/efileLogs/efileLogs.selector';
import ReturnExpired from './ReturnExpired';
import {selectTaxReturnsReducer} from 'src/store/taxReturns/taxReturns.selector';
import {getFilteredQueries} from 'src/CpaCenterV2/UserQueries/UserQueries.utils';
import PaperFilePopup from './PaperFilePopup';
import {selectUser} from 'src/store/user/user.selector';
import {getReturnName} from 'src/CpaCenterV2/common/CpaCenterV2.utils';
import {selectBusinessDetails} from 'src/store/businessDetails/businessDetails.selector';
import {ReduxStateType} from 'src/store/store';
import IPPinDetails from './IPPinDetails';

const PAPER_FILE_ALLOWED_STATUSES = [
  EFILE_STATUS_INFO.REJECTED,
  EFILE_STATUS_INFO.NOT_EFILED,
];

const RenderHeadingAndButtonCard = ({
  heading = '',
  buttonTitle = '',
  onClick = () => {},
}: {
  heading: string;
  buttonTitle: string;
  onClick: () => void;
}) => {
  return (
    <Box
      col
      backgroundColor={themmeColor.cpaCenterV2Bg}
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        paddingInline: 20,
        paddingBlock: 20,
      }}>
      <Typography
        style={{fontWeight: 600, textAlign: 'center', marginBottom: 16}}>
        {heading}
      </Typography>
      <DSButton
        style={{width: '50%'}}
        type="primary"
        text={buttonTitle}
        onClick={onClick}
      />
    </Box>
  );
};

const RenderCurrentInfo = ({
  step,
  returnId,
  returnType,
}: {
  step: TaxReturnStatus;
  returnId: number;
  returnType: TaxReturnType;
}) => {
  const dispatch = useDispatch();
  const {navigateToTaxReturnDetailedView, navigateToUserQueries} =
    useCpaCenterV2Navigation();

  const {threads} = useSelector((state: ReduxStateType) =>
    selectThreadsForCurrReturn(state, {
      return_id: returnId,
      return_type: returnType,
    }),
  );
  const {queries} = useSelector((state: ReduxStateType) =>
    selectQueriesForCurrReturn(state, {
      return_id: returnId,
      return_type: returnType,
    }),
  );
  const {efileLogs: currentReturnEfileLogs} = useSelector(
    (state: ReduxStateType) =>
      selectEfileLogsForCurrReturn(state, {
        return_id: returnId,
        return_type: returnType,
      }),
  );
  const {taxReturns} = useSelector(selectTaxReturnsReducer);
  const {userId} = useSelector(selectUser);

  const [showPaperFilePopup, setShowPaperFilePopup] = useState(false);

  const currentTaxReturn = taxReturns.find(
    (taxReturn) =>
      taxReturn.return_id === returnId && taxReturn.return_type === returnType,
  );
  const openNotesCount = threads.filter(
    (thread) => thread.status === ThreadStatus.OPEN,
  ).length;
  const openReviewEsignQueryCount = queries.filter(
    (query) =>
      query.querySubtype === QuerySubType.REVIEW_ESIGN &&
      !isQueryResolvedByCpaOrTerminallyResolved(query),
  ).length;

  const openQueryCount = getFilteredQueries(queries).filter(
    (query) => !isQueryResolvedByCpaOrTerminallyResolved(query),
  ).length;

  const allowPaperFiling = Object.values(
    currentTaxReturn?.efile_status_info ?? {},
  ).reduce((prev, efileStatus) => {
    return prev || PAPER_FILE_ALLOWED_STATUSES.includes(efileStatus);
  }, false);

  const paperFilingOptions = Object.keys(
    currentTaxReturn?.efile_status_info ?? {},
  ).filter((key) =>
    PAPER_FILE_ALLOWED_STATUSES.includes(
      currentTaxReturn?.efile_status_info[key],
    ),
  );

  const navigateToDetailedView = () => {
    navigateToTaxReturnDetailedView(returnId, returnType, step);
  };

  switch (step) {
    case TaxReturnStatus.SEND_TO_CCH: {
      return (
        <RenderHeadingAndButtonCard
          heading="Send all the information to CCH and prepare the tax return"
          buttonTitle="Sent to CCH"
          onClick={navigateToDetailedView}
        />
      );
    }
    case TaxReturnStatus.ADD_RETURN: {
      return (
        <RenderHeadingAndButtonCard
          heading="Upload return to get started"
          buttonTitle="Start"
          onClick={navigateToDetailedView}
        />
      );
    }
    case TaxReturnStatus.REVIEW_SUMMARY: {
      return (
        <>
          <ReturnExpired returnId={returnId} returnType={returnType} />
          <RenderHeadingAndButtonCard
            heading="Review return summary"
            buttonTitle="Start"
            onClick={navigateToDetailedView}
          />
        </>
      );
    }
    case TaxReturnStatus.CPA_REVIEW: {
      return (
        <>
          <ReturnExpired returnId={returnId} returnType={returnType} />
          <RenderHeadingAndButtonCard
            heading={`You have ${openNotesCount} pending notes`}
            buttonTitle="Continue"
            onClick={navigateToDetailedView}
          />
        </>
      );
    }
    case TaxReturnStatus.REVIEW_ESIGN: {
      return (
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div style={{flex: 0.5}}>
            <ReturnExpired returnId={returnId} returnType={returnType} />
            <RenderHeadingAndButtonCard
              heading={`${openReviewEsignQueryCount} open queries`}
              buttonTitle="Continue"
              onClick={navigateToDetailedView}
            />
          </div>
          <div style={{flex: 0.45}}>
            <ActivityTable
              logs={(currentTaxReturn?.esign_status_log ?? []).map((log) => ({
                event: log.title,
                date: log.date,
                type: log.completed
                  ? ActivityTable.TYPE.DONE
                  : ActivityTable.TYPE.PENDING,
              }))}
            />
          </div>
        </div>
      );
    }
    case TaxReturnStatus.EFILE_STATUS: {
      return (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <div style={{flex: 0.5}}>
              <Box
                borderColor={themmeColor.offWhite}
                hoverEffect
                onClick={() =>
                  dispatch(
                    setAppState(AppReducerStates.paymentDetailsDrawer, {
                      isVisible: true,
                      returnId,
                      returnType,
                    }),
                  )
                }
                hoverColor={themmeColor.focusedBlue}
                style={{
                  paddingBlock: 12,
                  paddingInline: 16,
                  justifyContent: 'space-between',
                  marginBottom: 20,
                  marginTop: 20,
                }}>
                <Typography style={{fontWeight: 600}}>
                  Payment details
                </Typography>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <TickIcon />
                  <Typography
                    style={{
                      fontSize: 13,
                      fontWeight: 600,
                      marginRight: 10,
                      marginLeft: 4,
                    }}>
                    Submitted
                  </Typography>
                  <RightChveron />
                </div>
              </Box>
              <IPPinDetails currentTaxReturn={currentTaxReturn} />
              {allowPaperFiling && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 20,
                  }}>
                  <Typography style={{fontWeight: 400, fontSize: 16}}>
                    If you want to request the user to paper file.
                  </Typography>
                  <DSButton
                    type="secondary"
                    text="Request user to paper file "
                    onClick={() => setShowPaperFilePopup(true)}
                    style={{marginTop: 4}}
                  />
                </div>
              )}
              {openQueryCount > 0 && (
                <div style={{marginTop: 20}}>
                  <RenderHeadingAndButtonCard
                    heading={`${openQueryCount} open queries`}
                    buttonTitle="Continue"
                    onClick={() => navigateToUserQueries(returnId, returnType)}
                  />
                </div>
              )}
            </div>
            <div style={{flex: 0.4}}>
              <Typography
                style={{fontSize: 20, fontWeight: 600, marginBottom: 8}}>
                {currentReturnEfileLogs.length} Efile Logs
              </Typography>
              <ActivityTable
                logs={currentReturnEfileLogs?.map((log) => ({
                  event: log.title,
                  date: log.date,
                  type: log.warning
                    ? ActivityTable.TYPE.WARNING
                    : ActivityTable.TYPE.DONE,
                }))}
              />
            </div>
          </div>
          <PaperFilePopup
            isOpen={showPaperFilePopup}
            onClose={() => setShowPaperFilePopup(false)}
            options={paperFilingOptions}
            userId={userId}
            returnId={returnId}
            returnType={returnType}
          />
        </>
      );
    }
  }
  return null;
};

interface ReturnStatusCardProps {
  taxReturn: TaxReturn;
  isAnyReturnSharedWithUser: boolean;
}

const REVIEW_ESIGN_STEP_HEADINGS = {
  [TaxReturnStatus.ADD_RETURN]: 'Add Return',
  [TaxReturnStatus.CPA_REVIEW]: 'Cpa Review',
  [TaxReturnStatus.EFILE_STATUS]: 'Efile status',
  [TaxReturnStatus.REVIEW_ESIGN]: 'Review e-sign',
  [TaxReturnStatus.REVIEW_SUMMARY]: 'Review Return Summary',
  [TaxReturnStatus.SEND_TO_CCH]: '',
};

const ReturnStatusCard = ({
  taxReturn,
  isAnyReturnSharedWithUser,
}: ReturnStatusCardProps) => {
  const {taxReturnSteps, currentStep} = useTaxReturnStep(
    taxReturn.return_id,
    taxReturn.return_type,
  );

  const {queries} = useSelector((state: ReduxStateType) =>
    selectQueriesForCurrReturn(state, taxReturn),
  );
  const [currentSelectedStep, setCurrentSelectedStep] = useState(currentStep);
  const {businesses} = useSelector(selectBusinessDetails);

  const {navigateToUserQueries} = useCpaCenterV2Navigation();

  const openQueryCount = getFilteredQueries(queries).filter(
    (query) => !isQueryResolvedByCpaOrTerminallyResolved(query),
  ).length;

  const isCurrentReturnSharedWithUser = [
    TaxReturnStatus.REVIEW_ESIGN,
    TaxReturnStatus.EFILE_STATUS,
  ].includes(taxReturn.status);

  const showOpenQueriesBox =
    isAnyReturnSharedWithUser && !isCurrentReturnSharedWithUser;

  return (
    <Box
      col
      style={{
        marginTop: 20,
        paddingBlock: 12,
        paddingInline: 20,
        maxWidth: '90%',
        marginBottom: 12,
      }}>
      <Typography style={{fontWeight: 600, marginBottom: 20}}>
        {getReturnName(taxReturn, businesses)}
      </Typography>
      <Steps
        data={taxReturnSteps}
        onClick={(item) => setCurrentSelectedStep(item.props.step)}
      />
      <div style={{marginTop: 20}}>
        <Typography style={{fontWeight: 600, fontSize: 18}}>
          {REVIEW_ESIGN_STEP_HEADINGS[currentSelectedStep]}
        </Typography>
        <div
          style={{
            marginTop: 12,
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <div style={{flex: showOpenQueriesBox ? 0.45 : 1}}>
            <RenderCurrentInfo
              returnId={taxReturn.return_id}
              returnType={taxReturn.return_type}
              step={currentSelectedStep}
            />
          </div>
          {showOpenQueriesBox && (
            <div style={{flex: 0.45}}>
              <RenderHeadingAndButtonCard
                heading={`${openQueryCount} open queries`}
                buttonTitle="View Queries"
                onClick={() =>
                  navigateToUserQueries(
                    taxReturn.return_id,
                    taxReturn.return_type,
                  )
                }
              />
            </div>
          )}
        </div>
      </div>
    </Box>
  );
};

export default ReturnStatusCard;
