import {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getDocument} from 'src/appApi';
import {fetchDocumentsForGivenYear} from 'src/store/documents/documents.actions';
import {ReduxDocumentType} from 'src/store/documents/documents.reducer';
import {
  selectDocuments,
  selectDocument,
} from 'src/store/documents/documents.selector';
import {ReduxStateType} from 'src/store/store';
import useCurrentDocumentId from '../hooks/useCurrentDocumentId';
import useCurrentUserId from '../hooks/useCurrentUserId';
import {CPA_CENTER_ACTIVE_YEAR, DOCUMENT_TYPES} from 'src/constants/constants';
import {TaxFormFieldIds} from 'src/store/taxForms/taxForms.constants';
import {selectActiveYear} from 'src/store/globals/globals.selector';

export const FORM_FIELDS_TO_HIDE = [TaxFormFieldIds.BUSINESS_ID];

interface Props {
  filterDoc?: (doc: ReduxDocumentType) => boolean;
  year?: number;
}

const useViewDocument = ({filterDoc = () => true, year}: Props = {}) => {
  const dispatch = useDispatch();
  const {docId} = useCurrentDocumentId();
  const {userId} = useCurrentUserId();
  const {documents, loaded} = useSelector(selectDocuments);
  const filteredDocuments = useMemo(
    () => documents.filter((doc) => filterDoc(doc)),
    [documents],
  );
  const cpaCenterActiveYear = useSelector(selectActiveYear);
  const yearToUse = year ? year : cpaCenterActiveYear;

  const currentDocument = useSelector((state: ReduxStateType) =>
    selectDocument(state, docId),
  ) as ReduxDocumentType;

  const [docReadUrl, setDocReadUrl] = useState('');
  const [isReadUrlReady, setIsReadUrlReady] = useState(false);
  const [imageScale, setImageScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const canvasPDFRef = useRef({});

  const documentsSequence = useMemo(() => {
    if (loaded) {
      const ocrDocuments = filteredDocuments.filter((doc) => doc.ocrRequired);
      const nonOcrDocuments = filteredDocuments.filter(
        (doc) => !doc.ocrRequired && doc.docType !== DOCUMENT_TYPES.OTHER,
      );
      const additionalDocuments = filteredDocuments.filter(
        (doc) => doc.docType === DOCUMENT_TYPES.OTHER,
      );

      return [...ocrDocuments, ...nonOcrDocuments, ...additionalDocuments];
    }

    return [];
  }, [filteredDocuments, loaded]);

  const originalPDFPages = {};
  let prevPage = -1;

  const getNextDocument = (docId: number) => {
    const documentIndex = documentsSequence.findIndex(
      (doc) => doc.docId === docId,
    );
    if (documentIndex === documentsSequence.length - 1) {
      return null;
    }
    return documentsSequence[documentIndex + 1];
  };

  const getPreviousDocument = (docId: number) => {
    const documentIndex = documentsSequence.findIndex(
      (doc) => doc.docId === docId,
    );
    if (documentIndex === 0) {
      return null;
    }
    return documentsSequence[documentIndex - 1];
  };

  const getReadUrl = async (docLink: string) => {
    const urlResponse = await getDocument(docLink);
    setDocReadUrl(urlResponse.data);
    setIsReadUrlReady(true);
  };

  useEffect(() => {
    if (loaded) {
      getReadUrl(currentDocument.docLink);
    }
  }, [loaded, currentDocument?.docLink]);

  const refreshDocuments = async () => {
    await dispatch(fetchDocumentsForGivenYear(userId, yearToUse));
  };

  const drawBoundingBox = (
    ocrData: any,
    canvasContext: CanvasRenderingContext2D | null,
    canvasWidth: number,
    canvasHeight: number,
    isImage: boolean,
  ) => {
    ocrData.forEach((data) => {
      if (data) {
        let width = data.Width * canvasWidth;
        let height = data.Height * canvasHeight;
        let x = data.Left * canvasWidth;
        let y = data.Top * canvasHeight;

        if (!isImage) {
          if (rotate % 4 === 0) {
            width = data.Width * canvasWidth;
            height = data.Height * canvasHeight;
            x = data.Left * canvasWidth;
            y = data.Top * canvasHeight;
          } else if (rotate % 3 === 0) {
            width = data.Height * canvasWidth;
            height = data.Width * canvasHeight;
            x = data.Top * canvasWidth;
            y = Math.abs(canvasHeight - data.Left * canvasHeight - height);
          } else if (rotate % 2 === 0) {
            width = data.Width * canvasWidth;
            height = data.Height * canvasHeight;
            x = Math.abs(canvasWidth - data.Left * canvasWidth - width);
            y = Math.abs(canvasHeight - data.Top * canvasHeight - height);
          } else {
            width = data.Height * canvasWidth;
            height = data.Width * canvasHeight;
            x = Math.abs(canvasWidth - data.Top * canvasWidth - width);
            y = data.Left * canvasHeight;
          }
        }

        canvasContext?.strokeRect(x, y, width, height);
      }
    });
  };

  const drawImageBBox = (ocrData: any) => {
    const highlightParentContainer = document.getElementById(`image-renderer`);
    const canvas = canvasRef.current;

    if (canvas) {
      const canvasContext = canvas.getContext('2d');
      canvasContext.clearRect(0, 0, canvas.width, canvas.height);
      const background = new Image();
      background.src = docReadUrl;

      // redraw image
      background.onload = () => {
        let currImageScale = imageScale;
        canvas.width = background.width;
        canvas.height = background.height;

        let drawingWidth = background.width * currImageScale;
        let drawingHeight = background.height * currImageScale;

        // checking if bounding box width is
        // big enough for zooming out
        if (ocrData[0] && ocrData[1]) {
          if (
            highlightParentContainer?.clientWidth / 2 <=
              ocrData[0].Width * drawingWidth ||
            highlightParentContainer?.clientWidth / 2 <=
              ocrData[1].Width * drawingWidth ||
            highlightParentContainer?.clientHeight / 2 <=
              ocrData[0].Height * drawingHeight ||
            highlightParentContainer?.clientHeight / 2 <=
              ocrData[1].Height * drawingHeight
          ) {
            currImageScale = 0.5;
            drawingWidth = background.width * currImageScale;
            drawingHeight = background.height * currImageScale;
          }
        }

        canvasContext?.scale(currImageScale, currImageScale);
        canvasContext.drawImage(background, 0, 0);
        canvasContext.beginPath();
        canvasContext.strokeStyle = 'red';
        canvasContext.lineWidth = 3;
        drawBoundingBox(
          ocrData,
          canvasContext,
          canvas.width,
          canvas.height,
          true,
        );

        // scrolling to the bounding box
        if (ocrData[1]) {
          highlightParentContainer?.scrollTo(
            ocrData[1].Left * drawingWidth -
              highlightParentContainer?.clientWidth / 4,
            ocrData[1].Top * drawingHeight -
              highlightParentContainer.clientHeight / 2,
          );
        }
        setImageScale(currImageScale);
      };
    }
  };

  const drawPDFBBox = (page: number, ocrData: any) => {
    const highlightContainer = document.getElementById(`Page-${page}`);
    // const highlightParentContainer = document.getElementById('pdf-renderer');
    highlightContainer?.scrollIntoView();

    // to remove previously drawn bounding box
    if (prevPage !== -1) {
      const prevCanvas = canvasPDFRef.current[prevPage];
      const prevCanvasContext = prevCanvas.getContext('2d');

      // condition to check for manual zoom and update originalPDFPages
      if (originalPDFPages[prevPage].height === prevCanvas.height) {
        prevCanvasContext.putImageData(originalPDFPages[prevPage], 0, 0);
      } else {
        originalPDFPages[prevPage] = prevCanvasContext.getImageData(
          0,
          0,
          prevCanvas.width,
          prevCanvas.height,
        );
      }
    }

    // logic to draw bounding box
    const canvas = canvasPDFRef.current[page];
    if (canvas) {
      const canvasContext = canvas?.getContext('2d');
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      canvasContext.strokeStyle = 'red';
      canvasContext.lineWidth = 5;

      if (!originalPDFPages[page]) {
        originalPDFPages[page] = canvasContext.getImageData(
          0,
          0,
          canvasWidth,
          canvasHeight,
        );
      }
      prevPage = page;
      drawBoundingBox(
        ocrData,
        canvasContext,
        canvasWidth,
        canvasHeight,
        false,
      );
    }
  };

  const isImage =
    loaded &&
    (currentDocument.docLink.toLowerCase().endsWith('jpg') ||
      currentDocument.docLink.toLowerCase().endsWith('png') ||
      currentDocument.docLink.toLowerCase().endsWith('jpeg'));

  return {
    docId,
    userId,
    document: currentDocument,
    refreshDocuments,
    documentsLoading: !loaded,
    docReadUrl,
    isReadUrlReady,
    canvasRef,
    canvasPDFRef,
    drawImageBBox,
    drawPDFBBox,
    imageScale,
    setImageScale,
    isImage,
    rotate,
    setRotate,
    getNextDocument,
    getPreviousDocument,
  };
};

export default useViewDocument;
