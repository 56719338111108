import {Typography} from '@mui/material';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import DSCheckBox from 'src/CpaCenterV2/common/DSCheckBox/DSCheckBox';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import {markPaperFiled} from 'src/appApi';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import {themmeColor} from 'src/constants/constants';
import {NotificationType} from 'src/store/app/app.reducer';
import {fetchEfileLogs} from 'src/store/efileLogs/efileLogs.actions';
import {fetchQueries} from 'src/store/queries/queries.actions';
import {fetchTaxReturns} from 'src/store/taxReturns/taxReturns.actions';
import {TaxReturnType} from 'src/store/taxReturns/taxReturns.reducer';
import {fetchUserInfo} from 'src/store/user/user.actions';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  options: string[];
  userId: number;
  returnId: number;
  returnType: TaxReturnType;
}

const PaperFilePopup = ({
  isOpen,
  onClose,
  options,
  userId,
  returnId,
  returnType,
}: Props) => {
  const {activeYear} = useActiveYear();
  const [selectedJurisdictions, setSelectedJurisdictions] = useState<string[]>(
    [],
  );
  const [loading, setLoading] = useState(false);

  const {notify} = useNotify();
  const dispatch = useDispatch();

  const handleOnChange = (isChecked: boolean, value: string) => {
    if (isChecked) {
      setSelectedJurisdictions((prev) => [...prev, value]);
    } else {
      setSelectedJurisdictions((prev) =>
        prev.filter((jurisdiction) => jurisdiction !== value),
      );
    }
  };

  const onClosePopup = () => {
    setSelectedJurisdictions([]);
    onClose();
  };

  const onSend = async () => {
    setLoading(true);
    try {
      await markPaperFiled({
        fly_user_id: userId,
        year: activeYear,
        jurisdictions: selectedJurisdictions,
        return_id: returnId,
        return_type: returnType,
      });
      await dispatch(fetchEfileLogs(userId));
      await dispatch(fetchTaxReturns(userId));
      await dispatch(fetchQueries({userId}));
      await dispatch(fetchUserInfo(userId));
      onClosePopup();
      notify('Send for Paper Filing success', NotificationType.success);
    } catch (e) {
      notify('Send for Paper Filing failed', NotificationType.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PopUp
      primaryButtonTitle="Send"
      primaryButtonOnClick={onSend}
      primaryButtonDisabled={selectedJurisdictions.length === 0 || loading}
      secondaryButtonTitle="Cancel"
      secondaryButtonOnClick={onClosePopup}
      secondaryButtonDisabled={loading}
      style={{width: 540, maxHeight: 600}}
      isOpen={isOpen}
      onClose={onClosePopup}>
      <Typography style={{fontWeight: 600, fontSize: 20}}>
        Select all items which requires paper filing
      </Typography>
      <Typography style={{fontWeight: 400, fontSize: 14, marginTop: 4}}>
        Please remember to send the mail to the user with tax return to paper
        file. Once you select the item and send, user will receive and
        automated message on their App.
      </Typography>
      <div style={{overflow: 'scroll'}}>
        {options.map((option) => {
          return (
            <div
              style={{
                display: 'flex',
                backgroundColor: themmeColor.offWhite,
                padding: 12,
                marginTop: 12,
              }}>
              <DSCheckBox
                isChecked={selectedJurisdictions.includes(option)}
                onChange={(isChecked) => handleOnChange(isChecked, option)}
              />
              <Typography
                style={{fontWeight: 600, fontSize: 16, marginLeft: 12}}>
                {option}
              </Typography>
            </div>
          );
        })}
      </div>
    </PopUp>
  );
};

export default PaperFilePopup;
