import {getHighValueDeductions} from 'src/appApi';
import {SET_HIGH_VALUE_DEDUCTIONS} from '../actionNames';
import {setAppState} from '../app/app.actions';
import {AppReducerStates, NotificationType} from '../app/app.reducer';
import {parseSingleTransaction} from '../transactions/transactions.utils';
import {HighValueDeductionStateType} from './highValueDeductions.reducer';
import {getStateType} from '../store';
import {selectActiveYear} from '../globals/globals.selector';

const setHighValueDeductions = (
  payload: Pick<HighValueDeductionStateType, 'deductions'>,
) => {
  return {
    type: SET_HIGH_VALUE_DEDUCTIONS,
    payload,
  };
};

export const fetchHighValueDeductions = (user_id: number) => {
  return async (dispatch: any, getState: getStateType) => {
    const activeYear = selectActiveYear(getState());
    try {
      const {data} = await getHighValueDeductions({
        user_id,
        app_year: activeYear,
      });
      const deductions = data.map((backendTxn) =>
        parseSingleTransaction(backendTxn),
      );
      dispatch(setHighValueDeductions({deductions}));
    } catch (e) {
      dispatch(
        setAppState(AppReducerStates.notifcationStatus, {
          isVisible: true,
          message: `Failed to fetch high value deductions (${e})`,
          type: NotificationType.error,
        }),
      );
    }
  };
};
