import React from 'react';
import {Menu as MenuMUI, MenuItem} from '@mui/material';
import { themmeColor } from 'src/constants/constants';

interface MenuProps {
  anchorEl: any;
  onClose: () => void;
  options: Array<string>;
  onClick: (newValue: string) => void;
}

const Menu = ({
  anchorEl,
  onClose,
  onClick,
  options,
}: MenuProps) => {
  const isMenuOpen = Boolean(anchorEl);

  const onSelect = (selectedOption: string) => {
    onClick(selectedOption);
    onClose();
  };

  return (
    <MenuMUI
      id="basic-menu"
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={onClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}>
      {options.map((option) => (
        <MenuItem key={option} onClick={() => onSelect(option)}>
          {option}
        </MenuItem>
      ))}
    </MenuMUI>
  );
};

export default Menu;
