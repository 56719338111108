import {Button} from '@mui/material';
import React, {useState} from 'react';
import {getUsersList} from 'src/appApi';
import {MAX_PER_PAGE_LIMIT} from 'src/constants/constants';
import Papa from 'papaparse';
import {BACKEND_RESPONSE_KEYS, Record, TAX_RETURN_STATUS_LABELS} from 'src/CpaCenterList/components/cpaList.utils';

interface ExportColumnsProps {
  getFilterParams: () => any;
}

const ExportColumns = ({getFilterParams}: ExportColumnsProps) => {
  const [loading, setLoading] = useState(false);
  const formatData = (data: Array<Record>) => {
    return data.map(
      ({
        [BACKEND_RESPONSE_KEYS.NOTE]: note,
        [BACKEND_RESPONSE_KEYS.STATUS]: cpa_status,
        ...rest
      }) => ({
        ...rest,
        // @ts-ignore
        [BACKEND_RESPONSE_KEYS.STATUS]:
          TAX_RETURN_STATUS_LABELS[cpa_status]?.label,
      }),
    );
  };
  const handleExportDownload = async () => {
    try {
      setLoading(true);
      const params = {
        ...getFilterParams(),
        per_page: MAX_PER_PAGE_LIMIT,
        page: 1,
      };
      const response = await getUsersList(params);
      const rawData = formatData(response.data.results);
      const csvData = Papa.unparse(rawData);

      const blob = new Blob([csvData], {type: 'text/csv'});
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');

      a.setAttribute('href', url);
      a.setAttribute('download', 'Columns.csv');
      a.click();
    } catch (e) {
      alert(`Something went wrong ${e}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      variant={'outlined'}
      disabled={loading}
      style={{
        marginRight: 8,
      }}
      onClick={handleExportDownload}>
      Export Columns
    </Button>
  );
};

export default ExportColumns;
