import React from 'react';
import {useSelector} from 'react-redux';
import {getCountyNameFromCountyCode} from 'src/common/utils';
import {TaxProfileSubsection} from 'src/constants/constants';
import {
  FIELD_ID,
  TAX_PROFILE_FIELDS,
} from 'src/CpaCenterUserInfo/CpaCenterTaxProfile/components/cpaTaxProfile.utils';
import MultiKeyValueField from 'src/CpaCenterV2/TaxProfileSections/common/MultiKeyValueField';
import SimpleKeyValueField from 'src/CpaCenterV2/TaxProfileSections/common/SimpleKeyValueField';
import SubSectionBox from 'src/CpaCenterV2/TaxProfileSections/common/SubSectionBox';
import {isSchoolDistrictCodeRequired} from 'src/CpaCenterV2/TaxProfileSections/TaxProfileSections.utils';
import {ReduxStateType} from 'src/store/store';
import {
  selectTaxProfile,
  selectTaxProfileAnswer,
} from 'src/store/taxProfile/taxProfile.selector';

interface HomeAddressProps {
  style?: React.CSSProperties;
}

const HomeAddress = ({style}: HomeAddressProps) => {
  const {loaded} = useSelector(selectTaxProfile);

  const homeAddress = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TAX_PROFILE_FIELDS.HOME_ADDRESS),
  );

  const livingOutside = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(state, TAX_PROFILE_FIELDS.LIVING_OUTSIDE_USA),
  );

  const outsideAddress = useSelector((state: ReduxStateType) =>
    selectTaxProfileAnswer(
      state,
      TAX_PROFILE_FIELDS.MAILING_ADDRESS_OUTSIDE_USA,
    ),
  );

  const getFields = () => {
    const fields = [];
    if (!loaded) {
      return fields;
    }

    fields.push(
      ...[
        {name: 'House number and street', path: FIELD_ID.STREET_NUMBER},
        {
          name: 'Apartment/Unit number (if applicable)',
          path: FIELD_ID.APARTMENT_NUMBER,
        },
        {name: 'City', path: FIELD_ID.CITY},
        {name: 'State', path: FIELD_ID.STATE},
        {
          name: 'County',
          path: FIELD_ID.COUNTY,
          parseReadValue: getCountyNameFromCountyCode,
        },
        {name: 'Zip code', path: FIELD_ID.ZIP_CODE},
      ],
    );

    if (isSchoolDistrictCodeRequired(homeAddress[FIELD_ID.STATE])) {
      fields.push({
        name: 'School district code',
        path: FIELD_ID.SCHOOL_DISTRICT_CODE,
      });
    }

    return fields;
  };

  return (
    <SubSectionBox
      name="Home address"
      style={style}
      taxprofileSubsection={TaxProfileSubsection.HomeAddress}>
      <SimpleKeyValueField
        name="I currently living outside USA"
        value={livingOutside}
        style={{marginBottom: 12}}
        loading={!loaded}
      />
      {livingOutside ? (
        <SimpleKeyValueField
          name="Mailing address outside USA"
          value={outsideAddress}
        />
      ) : (
        <MultiKeyValueField
          data={homeAddress}
          fields={getFields()}
          loading={!loaded}
        />
      )}
    </SubSectionBox>
  );
};

export default HomeAddress;
